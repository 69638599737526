import React from "react";
import styled from "styled-components";
import breakpoint from "Utils/breakpoint";
import PropTypes from "prop-types";
//labels
import Album12 from "Assets/img/disc/labels/album12.svg";
import Album10 from "Assets/img/disc/labels/album10.svg";
import CD from "Assets/img/disc/labels/cd.svg";
import Tape from "Assets/img/disc/labels/tape.svg";
import Digital from "Assets/img/disc/labels/digital.svg";

const DiscographyLabelWrap = styled.div`
  width: 115%;
  float: right;
  @media (min-width: ${breakpoint.lg}) {
    width: 150%;
  }

  .labelImg {
    width: 100%;
    display: inline-block;
    margin-bottom: 20px;
    &.digital {
      display: block;
      margin-left: -8%;
    }
    &.year {
      width: 65%;
    }
  }
`;

const DiscographyLabel = ({ yearLabel, labels }) => {
  return (
    <DiscographyLabelWrap>
      {yearLabel && yearLabel.img && (
        <img
          className="labelImg year"
          src={yearLabel.img}
          alt={yearLabel.title}
          title={yearLabel.title}
        />
      )}
      {labels && labels.album12 && (
        <img className="labelImg" src={Album12} alt='Album 12"' />
      )}
      {labels && labels.album10 && (
        <img className="labelImg" src={Album10} alt='Album 10"' />
      )}
      {labels && labels.cd && <img className="labelImg" src={CD} alt="CD" />}
      {labels && labels.tape && (
        <img className="labelImg" src={Tape} alt="Tape" />
      )}
      {labels && labels.digital && (
        <img className="labelImg digital" src={Digital} alt="Digital" />
      )}
    </DiscographyLabelWrap>
  );
};

DiscographyLabel.propTypes = {
  yearLabel: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
};

export default DiscographyLabel;
