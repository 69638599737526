import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styled from "styled-components";
import Facebook from "Assets/img/social/facebook.svg";
import Instagram from "Assets/img/social/instagram.svg";
import Twitter from "Assets/img/social/twitter.svg";
import YouTube from "Assets/img/social/youtube.svg";
import Copyright from "./Copyright";

const SocialMediaList = styled.div`
  user-select: none;
  position: relative;
  min-width: 200px;
  .socialMedia-copyright {
    position: absolute;
    bottom: -30px;
  }
`;

const SocialLink = styled.a`
  width: 40px;
  display: inline-block;
  margin-right: 10px;
`;

const SocialMedia = ({ className }) => {
  const socialMediaList = [
    {
      src: Facebook,
      url: "https://www.facebook.com/rustyeye",
      title: "Facebook",
    },
    {
      src: Twitter,
      url: "https://twitter.com/rustyeye",
      title: "Twitter",
    },
    {
      src: Instagram,
      url: "https://www.instagram.com/rustyeye/",
      title: "Instagram",
    },
    {
      src: YouTube,
      url: "https://www.youtube.com/rustyeyevideos",
      title: "YouTube",
    },
  ];

  return (
    <SocialMediaList className={classnames(className)}>
      <div>
        {socialMediaList.map((social, idx) => (
          <SocialLink key={idx} href={social.url} target="_blank">
            <img src={social.src} title={social.title} alt={social.title} />
          </SocialLink>
        ))}
      </div>
      <Copyright className="socialMedia-copyright" />
    </SocialMediaList>
  );
};

SocialMedia.propTypes = {
  className: PropTypes.string,
};

export default SocialMedia;
