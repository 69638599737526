import React from "react";
import styled from "styled-components";
import classnames from "classnames";
import breakpoint from "Utils/breakpoint";

const CopyrightText = styled.div`
  font-size: 11px;
  user-select: none;
  @media (min-width: ${breakpoint.sm}) {
    font-size: 16px;
  }
`;

const Copyright = ({ className }) => {
  return (
    <CopyrightText className={classnames(className)}>
      &copy; {new Date().getFullYear()} Epoch&eacute; Records
    </CopyrightText>
  );
};

export default Copyright;
