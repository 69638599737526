import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment-timezone";
import get from "lodash/get";
import { map } from "lodash";

const PostItemWrap = styled.div`
  margin-bottom: 50px;
  font-size: 1.25em;

  a {
    color: #3d3735;
    &:hover {
      text-decoration: none;
    }
  }
  p {
    font-size: 0.7em;
  }

  a.post-link {
    display: block;
  }
  .post-title {
    line-height: 1.1;
    font-weight: bold;
    margin: 0px auto 10px;
  }
  .post-date {
    margin: 10px auto 0px;
  }
  .post-img-wrap {
    width: 100%;
    height: 230px;
    overflow: hidden;
    .post-img {
      width: 100%;
      height: 100%;
      background-image: url(${(props) => props.postImg});
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      transition: all ease-in 0.5s;
    }
  }

  .post-excerpt {
    p {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .post-tags {
    font-size: 0.6em;
    a {
      &:hover {
        text-decoration: underline;
      }
      &::after {
        content: ", ";
      }
      &:last-child {
        &::after {
          content: "";
        }
      }
    }
  }

  &:hover,
  &:focus {
    .post-img-wrap {
      .post-img {
        transform: scale(1.2);
      }
    }
  }
`;

const PostItem = ({ post }) => {
  return (
    <PostItemWrap
      postImg={get(post, "_embedded['wp:featuredmedia'][0].source_url")}
    >
      <a className="post-link" href={post.link}>
        <div className="post-img-wrap">
          <div className="post-img"></div>
        </div>
        <div className="post-date fontTeko">
          {moment(get(post, "date")).format("ll")}
        </div>
        <div
          className="post-title fontTeko"
          dangerouslySetInnerHTML={{ __html: get(post, "title.rendered") }}
        />
        <div
          className="post-excerpt"
          dangerouslySetInnerHTML={{ __html: get(post, "excerpt.rendered") }}
        />
      </a>
      {/* tags */}
      {map(get(post, "_embedded['wp:term']"), (tags, i) => {
        return (
          <div key={i} className="post-tags">
            {map(tags, (tag, ii) => (
              <a key={`${i}_${ii}`} href={tag.link}>
                {tag.name}
              </a>
            ))}
          </div>
        );
      })}
    </PostItemWrap>
  );
};

// prop types for a post
const ContentPropType = PropTypes.shape({
  rendered: PropTypes.string,
  protected: PropTypes.bool,
});
const EmbeddedPropType = PropTypes.shape({
  author: PropTypes.arrayOf(PropTypes.object),
  "wp:featuredmedia": PropTypes.arrayOf(PropTypes.object),
  "wp:term": PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
});

export const PostPropType = PropTypes.shape({
  author: PropTypes.number,
  categories: PropTypes.arrayOf(PropTypes.number),
  comment_status: PropTypes.string,
  content: ContentPropType,
  date: PropTypes.string,
  date_gmt: PropTypes.string,
  excerpt: ContentPropType,
  featured_media: PropTypes.number,
  format: PropTypes.string,
  guid: ContentPropType,
  id: PropTypes.number,
  link: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.string),
  modified: PropTypes.string,
  modified_gmt: PropTypes.string,
  ping_status: PropTypes.string,
  slug: PropTypes.string,
  status: PropTypes.string,
  sticky: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.number),
  template: PropTypes.string,
  title: ContentPropType,
  type: PropTypes.string,
  _embedded: EmbeddedPropType,
});

PostItem.propTypes = {
  post: PostPropType.isRequired,
};

export default PostItem;
