import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import axios from "axios";
import * as serviceWorker from "./serviceWorker";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faSearch, faBars } from "@fortawesome/free-solid-svg-icons";

library.add(faSearch, faBars);

axios.defaults.headers.common = {
  Accept: "application/vnd.api+json, application/json, text/plain, */*",
  // "User-Agent": "RustyEye.com/1.0.0 (Contact via Form)",
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
