import React from "react";
import styled from "styled-components";
import PageWrap from "PageWrap";
import Menu from "./Menu";
import Newsletter from "./Newsletter";
import SkullREDF from "Assets/img/skull_REDF.svg";
import breakpoint from "Utils/breakpoint";

const Page = styled(PageWrap)`
  background-color: #5e92bf;
  color: #fff;
  .newsletter {
    margin-top: 50px;
  }
`;

const Title = styled.div`
  width: 100%;
  font-family: "Teko", sans-serif;
  font-size: 10px;
  transition: all 0.3s ease-in;
  h1 {
    font-size: 3em;
    font-weight: 600;
    line-height: 1;
    margin: 0;
    color: #184267;
    span {
      font-weight: 300;
    }
  }
  h2 {
    font-size: 12em;
    line-height: 0.8;
    margin: 0;
  }
  h3 {
    font-size: 2em;
    font-weight: 300;
  }

  @media (min-width: ${breakpoint.md}) {
    font-size: 16px;
    max-width: 70%;
  }

  @media (min-width: ${breakpoint.lg}) {
    font-size: 20px;
  }
`;

const SkullImg = styled.img`
  font-size: 10px;
  width: auto;
  height: 100%;
  max-height: 17em;
  transition: all 0.3s ease-in;

  @media (min-width: ${breakpoint.md}) {
    font-size: 16px;
  }

  @media (min-width: ${breakpoint.lg}) {
    font-size: 20px;
  }
`;

/** Index page that controls navigation */
const Index = () => {
  return (
    <Page logoColor="white">
      <div className="row">
        <div className="col-7">
          <Title>
            <h1>
              rustyeye.com <span>v6.1</span>
            </h1>
            <h2 className="fontWolfBrothers">INDEX</h2>
            <h3>Go to any chapter from here</h3>
          </Title>
        </div>
        <div className="col-5 text-right relative-position">
          <SkullImg src={SkullREDF} alt={"Skull R.E.D.F"} />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Menu />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Newsletter className="newsletter" />
        </div>
      </div>
    </Page>
  );
};

export default Index;
