import React from "react";
import styled from "styled-components";
import breakpoint from "Utils/breakpoint";
import classnames from "classnames";
import PageWrap, { PageContent } from "PageWrap";
import ScrollAnimation from "react-animate-on-scroll";

// import MrRust1 from "Assets/img/band/rust1.jpg";
import MrRust1Crop from "Assets/img/band/crop/rust1.jpg";
// import MrRust2 from "Assets/img/band/rust2.jpg";
import MrRust2Crop from "Assets/img/band/crop/rust2.jpg";
import MrRustSignature from "Assets/img/band/rustSignature.svg";

// import MsRandall1 from "Assets/img/band/randall1.jpg";
import MsRandall1Crop from "Assets/img/band/crop/randall1.jpg";
// import MsRandall2 from "Assets/img/band/randall2.jpg";
import MsRandall2Crop from "Assets/img/band/crop/randall2.jpg";
import MsRandallSignature from "Assets/img/band/randallSignature.svg";

// import Baron1 from "Assets/img/band/baron1.jpg";
import Baron1Crop from "Assets/img/band/crop/baron1.jpg";
import Baron2 from "Assets/img/band/baron2.jpg";
import Baron2Crop from "Assets/img/band/crop/baron2.jpg";
import BaronSignature from "Assets/img/band/baronSignature.svg";

const Page = styled(PageWrap)`
  background-color: #e66a44;
  color: #fff;
  /* prevent scrollbar on animation */
  overflow: hidden;
`;

const FullSizeImage = styled.div`
  width: 100%;
  height: auto;
  min-height: 500px;
  background-image: url(${(props) => props.image});
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  @media (min-width: ${breakpoint.sm}) {
    height: 97%;
    min-height: 600px;
  }
`;

const Quote = styled.div`
  color: #36302f;
  p {
    font-size: 1.2em;
    font-weight: bold;
  }
`;

const SignatureImg = styled.img`
  display: block;
  margin: auto;
  width: 50%;
  max-width: 300px;
`;

const MemberWrap = styled.div`
  font-size: 10px;
  margin-bottom: 20px;
  text-align: center;

  .memberTitle {
    display: inline-block;
    color: #36302f;
    font-size: 3.5em;
    line-height: 1;
    font-weight: bold;
    margin-right: 15px;
  }
  .memberPosition {
    display: inline-block;
    color: #f2d64b;
    font-size: 3em;
    line-height: 1;
    font-weight: lighter;
  }
  @media (min-width: ${breakpoint.sm}) {
    .memberTitle,
    .memberPosition {
      display: block;
    }
  }
  @media (min-width: ${breakpoint.md}) {
    text-align: left;
    font-size: 14px;
  }
  @media (min-width: ${breakpoint.lg}) {
    font-size: 16px;
  }
`;

const Member = ({ name, position, className }) => {
  return (
    <MemberWrap className={classnames(className)}>
      <div className="memberTitle">{name}</div>
      <div className="memberPosition">{position}</div>
    </MemberWrap>
  );
};

const ScrollAnimationImage = ({ children }) => (
  <ScrollAnimation
    animateIn="fadeIn"
    duration={1.6}
    animateOnce
    className="h-100"
  >
    {children}
  </ScrollAnimation>
);

const ScrollAnimationFlying = ({ children, right, left }) => (
  <ScrollAnimation
    animateIn={classnames({ fadeInRight: right }, { fadeInLeft: left })}
    duration={1.2}
    animateOnce
  >
    {children}
  </ScrollAnimation>
);

const Band = () => {
  return (
    <Page
      logoColor="white"
      pageTitleOptions={{
        title: "THE TRIO",
      }}
    >
      <PageContent>
        {/* Mr.Rust */}
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <ScrollAnimationImage>
              <Member
                className="d-block d-md-none"
                name="Mr. Rust"
                position="Bass &amp; Vocals"
              />

              {/* <FullSizeImage image={MrRust1} className="d-block d-md-none" /> */}
              <img className="img-fluid" src={MrRust1Crop} alt="Mr Rust" />

              <p className="fontHelvetica small text-right">
                <small>Photo by:@fabienphotography</small>
              </p>
            </ScrollAnimationImage>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <ScrollAnimationFlying right>
              <Member
                className="d-none d-md-block"
                name="Mr. Rust"
                position="Bass &amp; Vocals"
              />
              <p>
                <b> Mr. Rust</b> founded <b>Rusty Eye</b> back in the 90s with
                an aim to create a band with a unique sound and feel. After many
                early demos, he produced the debut album “
                <span>Rust n’ Roll</span>” (2001), which began{" "}
                <b>Rusty Eye’s</b> legacy of genre mashing with a blend of raw
                punk angst, metal edge and progressive feel. Throughout the
                years, his bass playing is drawn on the influence of music
                legends such as <span>Steve Harris</span>,{" "}
                <span>Geddy Lee</span>, <span>Cliff Burton</span> and{" "}
                <span>Lemmy Kilmister</span>. <b>Rust</b> is a versatile
                screamer known for his gravely vocals that bridge a wide palette
                of styles. Along with being the front man and mastermind of the
                band’s evolution, he is also credited for all art direction for{" "}
                <b>Rusty Eye</b>. <b>Mr. Rust</b> is the beating heart of the
                band, forever pushing new horizons and drawing out the innate
                talents of his bandmates.
              </p>
            </ScrollAnimationFlying>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <ScrollAnimationFlying left>
              <Quote>
                <p>
                  “I’ve seen so many trends and fads come and go, yet here we
                  are still putting out timeless records. From day one the aim
                  was to be original and defy the generic and the emulators”
                </p>
              </Quote>
              <SignatureImg
                className="img-fluid"
                src={MrRustSignature}
                alt="Mr. Rust"
              />
            </ScrollAnimationFlying>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <ScrollAnimationImage>
              {/* <FullSizeImage image={MrRust2} /> */}
              <img className="img-fluid" src={MrRust2Crop} alt="Mr Rust" />
              <p className="fontHelvetica small text-right">
                <small>Photo by: @fabienphotography</small>
              </p>
            </ScrollAnimationImage>
          </div>
        </div>

        {/* Miss Randall */}
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <ScrollAnimationImage>
              <Member
                className="d-block d-md-none"
                name="Miss Randall"
                position="Drums &amp; Vocals"
              />
              {/* <FullSizeImage
                image={MsRandall1}
                style={{ backgroundPosition: "60%" }}
              /> */}
              <img
                className="img-fluid"
                src={MsRandall1Crop}
                alt="Ms Randall"
              />
              <p className="fontHelvetica small text-right">
                <small>Photo by: @fabeinphotography</small>
              </p>
            </ScrollAnimationImage>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <ScrollAnimationFlying right>
              <Member
                className="d-none d-md-block"
                name="Miss Randall"
                position="Drums &amp; Vocals"
              />
              <p>
                <b>Miss Randall</b> joined <b>Rusty Eye</b> in 2003 and began
                the new dual vocal dynamic, while also laying down the drums for
                the new line-up. Additionally, she produced “
                <span>Cryogenic EP</span>” (2005), “
                <span>Stendhal Syndrome</span>” (2007), “<span>Possessor</span>”
                (2009) and “<span>Live at The Rainbow MMX</span>“(2010). Her
                ambition, hard work and technical abilities have made her a
                force to be reckoned with in the world of women in metal.
                Stylistically, she blends extreme drumming blasts interweaved
                with multi-stylistic grooves all while simultaneously singing
                and drumming. When singing, her techniques range between
                traditional melodic heavy metal and female nineties alternative
                rock, with a touch of the extreme. Never letting perceptions of
                others define her role in music, she blazes her trail boldly
                leaving a path for others to follow.
              </p>
            </ScrollAnimationFlying>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <ScrollAnimationFlying left>
              <Quote>
                <p>
                  “From the first time I picked up the mic and pair of sticks
                  it’s been a liberating experience. Through melody and rhythm I
                  get to convey my raw emotions, speak my true mind and leave a
                  legacy that dares those who do things differently to follow”
                </p>
              </Quote>
              <SignatureImg
                className="img-fluid"
                src={MsRandallSignature}
                alt="Ms Randall"
              />
            </ScrollAnimationFlying>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <ScrollAnimationImage>
              {/* <FullSizeImage image={MsRandall2} /> */}
              <img
                className="img-fluid"
                src={MsRandall2Crop}
                alt="Ms Randall"
              />
              <p className="fontHelvetica small text-right">
                <small>Photo by: @fabeinphotography</small>
              </p>
            </ScrollAnimationImage>
          </div>
        </div>

        {/* Baron Murtland */}
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <ScrollAnimationImage>
              <Member
                className="d-block d-md-none"
                name="Baron Murtland"
                position="Guitars &amp; Vocals"
              />
              {/* <FullSizeImage
                image={Baron1}
                style={{ backgroundPosition: "50%" }}
              /> */}
              <img className="img-fluid" src={Baron1Crop} alt="Barron" />

              <p className="fontHelvetica small text-right">
                <small>Photo by: @fabeinphotography</small>
              </p>
            </ScrollAnimationImage>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <ScrollAnimationFlying right>
              <Member
                className="d-none d-md-block"
                name="Baron Murtland"
                position="Guitars &amp; Vocals"
              />
              <p>
                Guitarist <b>Baron Murtland</b> joined <b>Rusty Eye</b> in 2005
                and the band assumed its final form. Stepping up to the
                occasion, he took on the role of producer for the albums “
                <span>Saca El Cobre</span>” (2013) and “
                <span>Dissecting Shadows</span>” (2020) and used his vision and
                attention to detail to push the band to new sonic possibilities.
                Influenced by guitarists the like of{" "}
                <span>Chuck Schuldiner</span>, <span>Dave Mustaine</span> and{" "}
                <span>James Hetfield</span>, his playing strikes a balance
                between technical intensity and organic flow. That style,
                coupled with his over the top stage presence has forged a
                reputation as an underground guitar hero.
              </p>
            </ScrollAnimationFlying>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <ScrollAnimationFlying left>
              <Quote>
                <p>
                  “Playing guitar, it’s been a journey of self discovery. You
                  have to open up your being, work hard and bleed, get crushed
                  down and still come back for more. Then, if you stick with
                  what you feel and push your limits hard, you begin to find
                  truth.”
                </p>
              </Quote>
              <SignatureImg
                className="img-fluid"
                style={{ width: "70%", maxWidth: "400px" }}
                src={BaronSignature}
                alt="Baron Signature"
              />
            </ScrollAnimationFlying>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <ScrollAnimationImage>
              <FullSizeImage
                image={Baron2}
                style={{ backgroundPosition: "50%" }}
                className="d-block d-md-none"
              />
              <img
                className="img-fluid d-none d-md-block"
                src={Baron2Crop}
                alt="Barron"
              />
              <p className="fontHelvetica small text-right">
                <small>Photo by: @fabienphotography</small>
              </p>
            </ScrollAnimationImage>
          </div>
        </div>
      </PageContent>
    </Page>
  );
};

export default Band;
