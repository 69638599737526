import React from "react";
// import { useState, useEffect } from "react";
import styled from "styled-components";
import PageWrap from "PageWrap";
// import ScrollAnimation from "react-animate-on-scroll";

// import productsList from "./products"; //HARD CODED PRODUCTS
// import map from "lodash/map";
// import axios from "axios";
// import ProductItem from "./ProductItem";
// import { getAPIUrl } from "Utils/helpers";
import StoreHero from "Assets/img/store-hero.jpg";

const Page = styled(PageWrap)`
  background-color: #f2d64b;
  color: #fff;
`;

const Store = () => {
  // const [loading, setLoading] = useState(false);
  // const [products, setProducts] = useState([]);

  // useEffect(() => {
  //   setLoading(true);
  //   axios.get(`${getAPIUrl(true)}/store/get-latest-store-data`).then((resp) => {
  //     setProducts(resp.data);
  //     setLoading(false);
  //   });
  // }, []);

  return (
    <Page
      logoColor="white"
      pageTitleOptions={{
        title: "STORE",
      }}
    >
      <div className="row">
        {/* {map(products, (product, idx) => {
          return (
            <div key={idx} className="col-12 col-sm-6 col-md-6 col-lg-4">
              <ScrollAnimation animateIn="fadeInUp" duration={1.5} animateOnce>
                <ProductItem {...product} />
              </ScrollAnimation>
            </div>
          );
        })}
        {products.length === 0 && !loading && (
          <div className="col-12 text-center">
            <h1 className="fontWolfBrothers" style={{ color: "#36302f" }}>
              Products not available at the moment
            </h1>
          </div>
        )} */}
        <a href="https://store.rustyeye.com/">
          <img
            className="img-fluid"
            src={StoreHero}
            alt="Click here to visit store"
          />
        </a>
      </div>
    </Page>
  );
};

export default Store;
