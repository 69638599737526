import React from "react";
import styled from "styled-components";
import RustyEyeLogo from "Assets/img/rustyEye_brown.svg";
import LoadingBar from "./LoadingBar";
import Copyright from "PageWrap/Copyright";
import PropTypes from "prop-types";
import classnames from "classnames";

const LoadingSection = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 20% 15% 5%;
  background: linear-gradient(
    180deg,
    rgba(54, 48, 47, 1) 0%,
    rgba(152, 146, 132, 1) 100%
  );
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden; /** prevent hand from overflowing */

  @media (min-width: 768px) {
    padding-top: 5%;
  }

  .loadingText {
    margin-top: 20px;
    font-weight: bold;
    font-size: 16px;
    display: none;
    @media (min-width: 768px) {
      display: block;
    }
  }
  .loadingFooter {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    text-align: center;
    padding: 20px;
    font-size: 14px;
    @media (min-width: 768px) {
      text-align: right;
    }
  }
`;

const Logo = styled.img`
  width: 100%;
  max-width: 800px;
  margin: auto;
  display: block;
  margin-bottom: 20px;
`;

const Loading = ({ percentage, loadIn, className }) => {
  return (
    <LoadingSection className={classnames(className)}>
      <Logo src={RustyEyeLogo} alt="Rusty Eye" />

      <LoadingBar percentage={percentage} loadIn={loadIn} />

      <div className="loadingText text-center text-uppercase">Loading</div>

      <Copyright className="loadingFooter" />
    </LoadingSection>
  );
};

Loading.propTypes = {
  loadIn: PropTypes.number,
  percentage: PropTypes.number,
  className: PropTypes.string,
};

export default Loading;
