import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import classnames from "classnames";
import breakpoint from "Utils/breakpoint";

const NewsletterContainer = styled.form`
  font-size: 16px;
  .title {
    font-size: 1.5em;
    transition: all 0.3s ease-in;
    @media (min-width: ${breakpoint.sm}) {
      font-size: 2em;
    }
    @media (min-width: ${breakpoint.md}) {
      font-size: 2.5em;
    }
    @media (min-width: ${breakpoint.lg}) {
      font-size: 3em;
    }
  }
  .btn-newsletter {
    background-color: #184267;
    color: #fff;
    &:active {
      background-color: rgba(24, 65, 103, 0.8);
      border-color: rgba(24, 65, 103, 0.8);
    }
  }
`;

const Newsletter = ({ className }) => {
  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    //allow default behavior of MailChimp
  };

  return (
    <NewsletterContainer
      className={classnames(className)}
      // action="https://rustyeye.us2.list-manage.com/subscribe/post?u=ab1efb596a949440a750781e6&amp;id=4130a9842b" //asks for fname and lname
      action="https://rustyeye.us2.list-manage.com/subscribe/post?u=ab1efb596a949440a750781e6&amp;id=a63f1ed222" //only needs email
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      target="_blank"
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <div className="title fontTeko">
        SUBSCRIBE TO THE RUSTY EYE NEWSLETTER
      </div>
      <div className="form-row align-items-center">
        <div className="col-8 col-sm-9 col-md-10">
          <label className="sr-only" htmlFor="email">
            Email
          </label>
          <input
            id="email"
            type="email"
            className="form-control form-control-lg"
            placeholder="Enter your email"
            required
            name="EMAIL"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="off"
          />
        </div>
        <input
          className="d-none"
          type="text"
          name="b_ab1efb596a949440a750781e6_a63f1ed222"
          tabindex="-1"
          value=""
        ></input>
        <div className="col-4 col-sm-3 col-md-2">
          <button
            type="submit"
            className="btn btn-lg btn-block btn-newsletter fontTeko"
          >
            SIGN UP
          </button>
        </div>
      </div>
    </NewsletterContainer>
  );
};

Newsletter.propTypes = {
  className: PropTypes.string,
};

export default Newsletter;
