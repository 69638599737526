import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import classnames from "classnames";
import DiscographyListenOn from "./DiscographyListenOn";
import DiscographyLabel from "./DiscographyLabel";
import RustyEyeButtonLink from "PageWrap/RustyEyeButtonLink";

const DiscographyContent = styled.div`
  .actionContent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    .itemTop {
      flex: 1;
    }
  }
  .btn-buy {
    margin-top: 30px;
  }
`;

const CoverImage = styled.div`
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  background-image: url(${(props) => props.cover});
  background-size: 170%;
  background-repeat: no-repeat;
  background-position: 45%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.12),
    0 4px 8px rgba(0, 0, 0, 0.12), 0 8px 16px rgba(0, 0, 0, 0.12),
    0 16px 32px rgba(0, 0, 0, 0.12), 0 32px 64px rgba(0, 0, 0, 0.12);
`;

const EmbedContainer = styled.div`
  iframe {
    width: 100%;
    height: 100%;
  }
  &.single {
    height: 80px;
  }
  height: 220px;
`;

const DiscographyItem = ({
  title,
  cover,
  single,
  yearLabel,
  labels,
  listenOn,
  iframe,
  buyRecord,
}) => {
  return (
    <DiscographyContent>
      <div className="row">
        <div className="col-2 col-lg-1 text-right">
          <DiscographyLabel yearLabel={yearLabel} labels={labels} />
        </div>

        <div className="col-10 col-lg-8">
          <CoverImage cover={cover} title={title} />
        </div>

        <div className="col-12 col-lg-3 actionContent">
          <DiscographyListenOn listenOn={listenOn} className="itemTop" />

          {iframe && (
            <EmbedContainer
              className={classnames({ single: single })}
              dangerouslySetInnerHTML={{ __html: iframe }}
            />
          )}

          {buyRecord && (
            <RustyEyeButtonLink
              className="btn btn-lg btn-block btn-buy"
              href={buyRecord}
              target="_blank"
              text="Buy Record"
            />
          )}
        </div>
      </div>
    </DiscographyContent>
  );
};

DiscographyItem.propTypes = {
  title: PropTypes.string.isRequired,
  cover: PropTypes.string.isRequired,
  single: PropTypes.bool,
  yearLabel: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  listenOn: PropTypes.object.isRequired,
  iframe: PropTypes.string.isRequired,
  buyRecord: PropTypes.string.isRequired,
};

export default DiscographyItem;
