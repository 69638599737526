import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PageWrap from "PageWrap";
import PostItem from "./PostItem";
import BlogHeader from "./BlogHeader";
import BlogFooter from "./BlogFooter";
import ScrollAnimation from "react-animate-on-scroll";
import { useParams } from "react-router-dom";
import axios from "axios";
import map from "lodash/map";
import get from "lodash/get";
import { getAPIUrl } from "Utils/helpers";
import queryString from "query-string";

const Page = styled(PageWrap)`
  background-color: #7f766b;
  color: #fff;
`;

const BlogContent = styled.div`
  background-color: #fff;
  color: #36302f;
  padding: 20px;
  min-height: 300px;
`;

//Note: due to transition group, the component remounts every time the location is change
const Blog = () => {
  let { page } = useParams();
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const url = `${getAPIUrl()}`;
  const PER_PAGE = 12;

  useEffect(() => {
    let queryParams = {
      _embed: true,
      order: "desc",
      per_page: PER_PAGE,
    };
    if (page) {
      queryParams = {
        ...queryParams,
        page: page,
      };
    }
    setLoading(true);
    axios
      .get(`${url}/posts?${queryString.stringify(queryParams)}`)
      .then((resp) => {
        setTotalPages(Number.parseInt(get(resp, "headers['x-wp-totalpages']")));
        //wait for page transition to be over before rendering data
        setTimeout(() => {
          setPosts(resp.data);
          setLoading(false);
        }, 1000);
      });
  }, [url, page]);

  return (
    <Page
      logoColor="white"
      pageTitleOptions={{
        title: "BLOG",
      }}
    >
      <BlogHeader />
      <BlogContent>
        {loading && (
          <div className="d-flex align-items-center mt-3 mb-3">
            <strong className="fontTeko">Loading...</strong>
            <div
              className="spinner-border ml-auto"
              role="status"
              aria-hidden="true"
            ></div>
          </div>
        )}

        <div className="row">
          {map(posts, (post, idx) => {
            return (
              <div key={idx} className="col-12 col-sm-6 col-lg-4">
                <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce>
                  <PostItem post={post} />
                </ScrollAnimation>
              </div>
            );
          })}
        </div>

        <BlogFooter
          currentPage={page ? Number.parseInt(page) : 1}
          totalPages={totalPages}
        />
      </BlogContent>
    </Page>
  );
};

export default Blog;
