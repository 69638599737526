import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styled from "styled-components";
import BackArrowUp from "Assets/img/back_arrow_up.svg";
import NextArrowRight from "Assets/img/next_arrow_right.svg";
import NextArrowDown from "Assets/img/next_arrow_down.svg";
import HomeArrowLeft from "Assets/img/home_arrow_left.svg";
import IndexArrowLeft from "Assets/img/index_arrow_left.svg";
import { useHistory } from "react-router-dom";
import breakpoint from "Utils/breakpoint";

const NavigationArrowImg = styled.img`
  width: auto;
  height: 60px;
  display: inline-block;
  user-select: none;
  cursor: pointer;
  transition: all 0.5s ease-in;
  @media (min-width: ${breakpoint.sm}) {
    height: 100px;
  }
  @media (min-width: ${breakpoint.md}) {
    height: 120px;
  }
  @media (min-width: ${breakpoint.lg}) {
    height: 150px;
  }
`;

/**
 * Creates a navigation arrow depending on the type
 * Path is require otherwise it wont render
 */
const NavigationArrow = ({
  type = "backTop",
  className,
  title,
  path,
  pulse = false,
}) => {
  const history = useHistory();
  const [hoverPulse, setHoverPulse] = useState(false);
  // hide navigation arrow if not path is set
  if (path === undefined || path === "") {
    return "";
  }

  let ImageSrc;
  switch (type) {
    case "backUp":
      ImageSrc = BackArrowUp;
      break;
    case "nextRight":
      ImageSrc = NextArrowRight;
      break;
    case "nextDown":
      ImageSrc = NextArrowDown;
      break;
    case "homeLeft":
      ImageSrc = HomeArrowLeft;
      break;
    case "indexLeft":
      ImageSrc = IndexArrowLeft;
      break;
    default:
      ImageSrc = "n/a";
      break;
  }

  return (
    <NavigationArrowImg
      src={ImageSrc}
      className={classnames(className, "", {
        "animated pulse infinite": pulse || hoverPulse,
      })}
      alt={title}
      title={title}
      onClick={() => {
        history.push(path);
      }}
      onMouseEnter={() => setHoverPulse(true)}
      onMouseLeave={() => setHoverPulse(false)}
    />
  );
};

NavigationArrow.propTypes = {
  type: PropTypes.oneOf([
    "backUp",
    "nextRight",
    "nextDown",
    "homeLeft",
    "indexLeft",
  ]),
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  path: PropTypes.string,
  pulse: PropTypes.bool,
};

export default NavigationArrow;
