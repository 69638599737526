import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SocialMedia from "PageWrap/SocialMedia";
import Copyright from "PageWrap/Copyright";
import NavigationArrow from "PageWrap/NavigationArrow";
import BandDrawn from "Assets/img/rustyEye_drawn_band.png";
import RustyEyeLogo from "Assets/img/rustyEye_black.svg";
import DissectingShadows from "Assets/img/dissecting_shadows.svg";
import Quotes from "./Quotes";
import breakpoint from "Utils/breakpoint";
import RustyEyeButtonLink from "PageWrap/RustyEyeButtonLink";
import { useHistory } from "react-router-dom";

const Page = styled.div`
  background-color: #989284;
  color: #fff;
  min-height: 100vh;
  padding: 25px;
  @media (min-width: ${breakpoint.md}) {
    padding: 50px 80px;
  }
`;

const BandImage = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  right: 0px;
  background-image: url(${BandDrawn});
  background-size: 120%;
  background-repeat: no-repeat;
  background-position: bottom center;
  transition: all 0.5s ease-in-out;
  @media (min-width: ${breakpoint.md}) {
    top: 0px;
    width: 80%;
    padding: 50px 80px;
    background-size: 100%;
    background-position: bottom right;
  }
  @media (min-width: ${breakpoint.lg}) {
    background-size: contain;
  }
  @media (min-width: ${breakpoint.xl}) {
    width: 90%;
  }
`;

const Footer = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0;
  z-index: 10;
  padding-top: 0px;
  padding: 25px;
  @media (min-width: ${breakpoint.md}) {
    padding: 50px 80px;
  }
  .nav-copyright {
    position: absolute;
    right: 15px;
    bottom: 0px;
  }
`;

const Content = styled.div`
  z-index: 20;
  position: relative;
  text-align: center;
  .logos {
    width: 90%;
    max-width: 250px;
    display: inline-block;
    text-align: center;
    margin-bottom: 20px;
    text-align: center;
    .main {
      margin-bottom: 10px;
    }
    .subtitle {
      width: 80%;
    }
  }
  @media (min-width: ${breakpoint.sm}) {
    text-align: left;
    .logos {
      max-width: 400px;
      margin-bottom: 50px;
    }
  }

  .notFound {
    max-width: 320px;
    text-align: center;
    margin: auto;
    h1 {
      font-size: 4.5em;
    }
    @media (min-width: ${breakpoint.sm}) {
      margin: 0px 40px;
    }
  }
`;

const Home = ({ pageNotFound }) => {
  const history = useHistory();
  return (
    <Page className="container-fluid">
      <Content>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="logos">
              <img className="main" src={RustyEyeLogo} alt="Rusty Eye" />
              <img
                className="subtitle"
                src={DissectingShadows}
                alt="Dissecting Shadows"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-8 col-lg-6 text-left">
            {!pageNotFound && <Quotes />}

            {pageNotFound && (
              <div className="notFound">
                <h1 className="fontWolfBrothers">Page not found</h1>
                <RustyEyeButtonLink
                  large
                  dark
                  text="Go To Index Page"
                  onClick={() => {
                    history.push("/indexPage");
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </Content>

      <BandImage />

      <Footer>
        <div className="row align-items-end">
          <div className="d-none d-md-block col-sm-4">
            <SocialMedia />
          </div>
          <div className="col-12 col-sm-12 col-md-4 text-center position-relative">
            <NavigationArrow
              type="nextDown"
              title="Next"
              path="/indexPage"
              pulse
            />

            <Copyright className="d-block d-md-none nav-copyright" />
          </div>
        </div>
      </Footer>
    </Page>
  );
};

Home.propTypes = {
  pageNotFound: PropTypes.bool, // shows a a page not found message
};

export default Home;
