import React from "react";
import styled from "styled-components";
import PageWrap, { PageContent } from "PageWrap";
import classnames from "classnames";
import ScrollAnimation from "react-animate-on-scroll";
import SkullPurple from "Assets/img/skull_purple.svg";
import Band01 from "Assets/img/bio/band01.jpg";
import Band02 from "Assets/img/bio/band02.jpg";
import Band03 from "Assets/img/bio/band03.jpg";
import SkullRELA from "Assets/img/bio/skull_RELA.png";
import BandAutographs from "Assets/img/bio/band-autographs.jpg";
import BandPortrait from "Assets/img/bio/band-portrait.jpg";
// Dividing icons
import SkullStar from "Assets/img/bio/skull_star.svg";
import Butterfly from "Assets/img/bio/butterfly.svg";
import Knife from "Assets/img/bio/knife.svg";
import Branch from "Assets/img/bio/branch.svg";
import Bone from "Assets/img/bio/bone.svg";
import BirdSkull from "Assets/img/bio/bird_skull.svg";
import Snake from "Assets/img/bio/snake.svg";
import Mushroom from "Assets/img/bio/mushroom.svg";
import Wings from "Assets/img/bio/wings.svg";
//logos
import ESP from "Assets/img/bio/esp.svg";
import EMG from "Assets/img/bio/emg.svg";
import Ashdown from "Assets/img/bio/ashdown.svg";
import Ddrum from "Assets/img/bio/ddrum.svg";
import Moog from "Assets/img/bio/moog.svg";
import TcHelicon from "Assets/img/bio/tcHelicon.svg";

import breakpoint from "Utils/breakpoint";

const Page = styled(PageWrap)`
  background-color: #755083;
  color: #fff;
  &.reducePadding {
    .contentRow {
      margin-top: 0px !important;
    }
  }

  .quote {
    font-size: 10px;
    h1 {
      font-size: 3em;
      margin: 0px;
    }
    p {
      font-size: 1.5em;
      margin: 0px;
    }
    @media (min-width: ${breakpoint.sm}) {
      font-size: 12px;
    }
    @media (min-width: ${breakpoint.md}) {
      font-size: 14px;
    }
    @media (min-width: ${breakpoint.lg}) {
      font-size: 16px;
    }
  }

  .logos {
    font-size: 12px;
    @media (min-width: ${breakpoint.sm}) {
      font-size: 16px;
    }
    .col-4 {
      min-height: 40px;
    }
  }
`;

const DividingIcon = styled.img`
  display: block;
  width: 100px;
  margin: 20px auto;

  @media (min-width: ${breakpoint.sm}) {
    width: 120px;
  }
  @media (min-width: ${breakpoint.lg}) {
    width: 150px;
  }

  &.small {
    width: 40px;
    @media (min-width: ${breakpoint.sm}) {
      width: 50px;
    }
    @media (min-width: ${breakpoint.lg}) {
      width: 60px;
    }
  }
`;

const SkullRow = styled.div`
  width: 100%;
  height: 40px;
  background-image: url(${SkullPurple});
  background-size: 40px 40px;
  background-repeat: space round;
  transition: all 0.3s ease-in-out;
  margin: 20px 0;

  @media (min-width: ${breakpoint.sm}) {
    height: 50px;
    background-size: 50px 50px;
  }
  @media (min-width: ${breakpoint.md}) {
    height: 80px;
    background-size: 80px 80px;
  }
  @media (min-width: ${breakpoint.lg}) {
    height: 100px;
    background-size: 100px 100px;
  }
`;

const ScrollAnimationWrap = ({ children, right, left }) => (
  <ScrollAnimation
    animateIn={classnames(
      { fadeInRight: right },
      { fadeInLeft: left },
      { fadeIn: !right && !left }
    )}
    duration={1.2}
    animateOnce
  >
    {children}
  </ScrollAnimation>
);

const Biography = () => {
  return (
    <Page
      logoColor="white"
      className="reducePadding"
      pageTitleOptions={{
        // title: "Biography",
        logoColor: "white",
        textAlign: "left",
      }}
    >
      <PageContent>
        <div className="row quote">
          <div className="col-12 offset-sm-2 col-sm-10 offset-md-2 col-md-10 text-right fontTeko">
            <ScrollAnimationWrap>
              <h1>
                unleashes a powerful brew of heavy metal wizardry and stunning
                sonic storytelling.
              </h1>
              <p>By Ryan J. Downey</p>
            </ScrollAnimationWrap>
          </div>
        </div>

        <ScrollAnimationWrap>
          <SkullRow />
        </ScrollAnimationWrap>

        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 col-lg-4">
            <ScrollAnimationWrap left>
              <p>
                Imagine a summer Saturday midnight-massacre movie, in a crusty
                old theater on the wrong side of town, as <span>Lemmy’s</span>{" "}
                ghost shovels fistfuls of your popcorn, <span>Jerry Only</span>{" "}
                kicks your seat, and <span>Steve Harris</span> hammers away on
                the old organ, and you’ll begin to understand what{" "}
                <b>RUSTY EYE</b> represents.
              </p>
            </ScrollAnimationWrap>
          </div>

          <DividingIcon className="small d-block d-sm-none" src={SkullStar} />

          <div className="col-12 col-sm-6 col-md-6 col-lg-8">
            <ScrollAnimationWrap>
              <img className="img-fluid" src={Band01} alt="Band" />
              <p className="fontHelvetica small text-right">
                <small>Photo by: @fabienphotography</small>
              </p>
            </ScrollAnimationWrap>
          </div>
        </div>

        <DividingIcon className="small d-none d-sm-block" src={SkullStar} />
        <DividingIcon src={Butterfly} className="d-block d-sm-none" />

        <div className="row">
          <div className="col-12">
            <ScrollAnimationWrap>
              <p>
                A fierce trio formed in <span>Mexico City</span>, who’ve made{" "}
                <span>Hollywood</span> their home, RUSTY EYE takes heavy metal
                storytelling to new heights of cinematic glory, passionately
                blending seemingly disparate styles into a unique mosaic, devoid
                of easy-to-spot seams. Theatrical epics and pissed off rock n’
                roll collide. The RUSTY EYE sound gobbles up prog, thrash, punk,
                blackened death, and synthwave, all squeezed through a keyhole
                of grindhouse grime that utterly defies simplified
                classifications.{" "}
              </p>
            </ScrollAnimationWrap>
          </div>
        </div>

        <DividingIcon src={Butterfly} className="d-none d-sm-block" />
        <DividingIcon src={Knife} className="d-block d-sm-none" />

        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
            <ScrollAnimationWrap>
              <img className="img-fluid" src={Band02} alt="Band" />
              <p className="fontHelvetica small text-right">
                <small>Photo by: @fabienphotography</small>
              </p>

              <img
                className="img-fluid d-none d-lg-block"
                src={SkullRELA}
                alt="Skull RELA"
              />
            </ScrollAnimationWrap>

            <DividingIcon src={Branch} className="d-block d-sm-none" />
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
            <ScrollAnimationWrap right>
              <p>
                Hardworking DIY experts with blue-collar ethos and artisan
                flair, <b>RUSTY EYE</b> have cast them-selves as
                larger-than-life filmland heroes and villains, with{" "}
                <span>KISS</span>-like stature. There’s drummer and co-vocalist{" "}
                <b>Miss Randall</b>, a student of prog-rock, in command of all
                that female-fronted diva metal entails. There’s the rasp and
                growl of bassist and co-vocalist <b>Mr. Rust</b>, recognized by
                the international metal community as resident DJ at the{" "}
                <span>Rainbow Bar &amp; Grill</span>, whose{" "}
                <span>Gene Simmons</span>-esque delivery and business acumen has
                helped amass five studio albums and a catalog of EPs, live
                records, singles, and music videos, all self-financed through
                their own <b>Epoché Records</b>. And there’s guitarist{" "}
                <b>Baron Murtland</b>, whose headbanging guitar mastery blends
                technical prowess and doomy atmosphere with mystifying alchemy,
                following in the tradition of the great titans of hard rock
                guitar.
              </p>
            </ScrollAnimationWrap>
          </div>

          <ScrollAnimationWrap>
            <img
              className="img-fluid mx-auto d-block d-lg-none"
              style={{ maxWidth: "50%" }}
              src={SkullRELA}
              alt="Skull RELA"
            />
          </ScrollAnimationWrap>
        </div>

        <DividingIcon src={Knife} className="d-none d-sm-block" />

        <div className="row">
          <div className="col-12">
            <ScrollAnimationWrap>
              <p>
                <b>RUSTY EYE</b> has been featured in <span>Noisey</span>,{" "}
                <span>Revolver</span>, <span>Metal Sucks</span>,{" "}
                <span>Metal Edge</span>, <span>Music Connection</span>,{" "}
                <span>Highwire Daze</span>,{" "}
                <span>Brave Words &amp; Bloody Knuckles</span>,{" "}
                <span>Zero Tolerance</span>, <span>Exclaim</span>, and a number
                of other outlets, with favorable press in the horror world as
                well, like <span>Rue Morgue</span> and the defunct{" "}
                <span>FEARnet.com</span>. They’ve performed at legendary{" "}
                <span>West Coast</span> venues like <span>The Regent</span>,{" "}
                <span>Roxy</span>, <span>Whisky A Go Go</span> and the legendary{" "}
                <span>Key Club</span>, which once dominated the{" "}
                <span>Sunset Strip</span>. In the spring of 2020, they hit the{" "}
                <span>Spotify</span>-curated playlist Metal de Nuestras Tierras
                (Metal from Our Lands).
              </p>
            </ScrollAnimationWrap>
          </div>
        </div>

        <DividingIcon src={Branch} className="d-none d-sm-block" />
        <DividingIcon src={Bone} className="d-block d-sm-none" />

        <div className="row">
          <div className="col-12">
            <ScrollAnimationWrap>
              <img className="img-fluid" src={Band03} alt="Band" />
            </ScrollAnimationWrap>
          </div>
        </div>

        <DividingIcon src={Bone} className="d-none d-sm-block" />

        <div className="row">
          <div className="col-12">
            <ScrollAnimationWrap>
              <p>
                The essential elements of “the three Ms” – <span>Misfits</span>,{" "}
                <span>Motörhead</span>, and <span>Maiden</span> – were there
                from the band’s earliest rumblings, weaving through songs like “
                <span>Mr. Cannibal</span>,” “<span>Cryonic Suspension</span>,”
                and “<span>The Entity (Ghostly Lust)</span>,” and across studio
                albums like <span>Rust N’ Roll</span> (2001),{" "}
                <span>Stendhal Syndrome</span> (2007), and the innovative horror
                breakthrough <span>Possessor</span> (2009). But there is so much
                more in play, from gentle undertones of <span>The Cure</span>{" "}
                and <span>Depeche Mode</span>, to the New Wave Of British Heavy
                Metal, to even the picking style of Norwegian black metal,
                without sounding overly complicated or confused.{" "}
                <b>RUSTY EYE</b> never emulates any one genre. It’s just purely
                Rust N’ Roll.
              </p>
            </ScrollAnimationWrap>
          </div>
        </div>

        <DividingIcon src={BirdSkull} />

        <div className="row">
          <div className="col-12 col-sm-6 col-md-6">
            <ScrollAnimationWrap left>
              <p>
                In 2014, the trio gathered together an album’s worth of Spanish
                language classics by <span>Cuca</span>,{" "}
                <span>Amantes de Lola</span>, <span>Fobia</span>,{" "}
                <span>Caifanes</span>, <span> Café Tacuba</span>, and{" "}
                <span>Maldita Vecindad</span>, alongside “
                <span>Sr. Cannibal</span>” (a new version of their own “
                <span>Mr. Cannibal</span>”), and put them through the{" "}
                <b>RUSTY EYE</b> blender of thrash, punk, stoner, and prog.
                Released together as the <span>Saca el Cobre</span> album, it
                caught the attention of famed Mexican musician and filmmaker{" "}
                <span>Sergio Arau</span>, who hired <b>RUSTY EYE</b> to be his
                backing band.
              </p>
            </ScrollAnimationWrap>

            <DividingIcon src={Snake} className="d-blok d-sm-none" />
          </div>
          <div className="col-12 col-sm-6 col-md-6">
            <ScrollAnimationWrap>
              <img className="img-fluid" src={BandAutographs} alt="Band" />
              <p className="fontHelvetica small text-right">
                <small>Photo by: Gabriel Pacheco</small>
              </p>
            </ScrollAnimationWrap>
          </div>
        </div>

        <DividingIcon src={Snake} className="d-none d-sm-block" />
        <DividingIcon src={Mushroom} className="small d-block d-sm-none" />

        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 col-lg-5 order-2 order-sm-1">
            <ScrollAnimationWrap>
              <img className="img-fluid" src={BandPortrait} alt="Band" />
              <p className="fontHelvetica small text-right">
                <small>Photo by: @fabienphotography</small>
              </p>
            </ScrollAnimationWrap>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-7 order-1 order-sm-2">
            <ScrollAnimationWrap right>
              <p>
                <span>Dissecting Shadows</span>, unleashed in 2020, is the most
                ambitious <b>RUSTY EYE</b> recording thus far, encompassing all
                of the strengths of the band’s past with a bold look toward the
                future. The entire album celebrates exploitation cinema, Italian
                horror, and grindhouse, executed with top-notch musicianship,
                songwriting, and creative originality. The fierce and fast “
                <span>This is Permanent</span>” disguises darkness in
                catchiness. It’s lyrics exam the perils of addiction, without
                being preachy; it plays with the idea of being “under the
                influence” (whether that’s of drugs, the media, other people…).
                “<span>Hellbound Witch</span>” delves into the band’s love of
                the occult, sensuality, and Halloween, with echoes of the Salem
                witch trials, draped in an ambience of persecution. The epic “
                <span>Mrs. Baylock</span>” is about the demonic nanny and
                devilish servant of the 1976 horror classic{" "}
                <span>The Omen</span>.
              </p>
            </ScrollAnimationWrap>

            <DividingIcon
              src={SkullPurple}
              className="small d-block d-sm-none"
            />
          </div>
        </div>

        <DividingIcon src={Mushroom} className="small d-none d-sm-block" />
        <DividingIcon src={Knife} className="d-block d-sm-none" />

        <div className="row">
          <div className="col-12">
            <ScrollAnimationWrap>
              <p>
                <b>RUSTY EYE</b> consistently defies expectations, boundaries,
                and trends, with the same drive of sweat and self-sufficiency
                that first ignited the band in Mexico City. The <b>RUSTY EYE</b>{" "}
                experience is a wild theme park ride of thrills, chills, and
                mischievous mayhem, stuffed with the theatricality and
                cleverness that keeps heavy metal music timeless, iconic,
                enduring, and gleefully reinvigorating.
              </p>
            </ScrollAnimationWrap>
          </div>
        </div>

        <DividingIcon src={Wings} />

        <div className="row justify-content-center">
          <div className="col-12">
            <ScrollAnimationWrap>
              <p className="text-center pt-5">
                <span>The Eye endorses the following brands:</span>
              </p>
            </ScrollAnimationWrap>
          </div>

          <div className="col-11 col-sm-8">
            <ScrollAnimationWrap>
              <div className="row logos d-flex justify-content-center text-center pt-5">
                <div className="col-4 mb-5">
                  <img
                    className="img-fluid"
                    src={ESP}
                    alt="esp"
                    style={{ height: "2em" }}
                  />
                </div>
                <div className="col-4 mb-5">
                  <img
                    className="img-fluid"
                    src={EMG}
                    alt="EMG"
                    style={{
                      height: "2.5em",
                      position: "relative",
                      top: "-5px",
                    }}
                  />
                </div>
                <div className="col-4 mb-5">
                  <img
                    className="img-fluid"
                    src={Ashdown}
                    alt="Ashdown"
                    style={{ height: "1.8em" }}
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid"
                    src={Ddrum}
                    alt="Ddrum"
                    style={{
                      height: "2em",
                      position: "relative",
                      top: "-13px",
                    }}
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid"
                    src={Moog}
                    alt="Moog"
                    style={{ height: "2.5em" }}
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid"
                    src={TcHelicon}
                    alt="TcHelicon"
                    style={{ height: "1em" }}
                  />
                </div>
              </div>
            </ScrollAnimationWrap>
          </div>
        </div>
      </PageContent>
    </Page>
  );
};

export default Biography;
