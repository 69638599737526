import React from "react";
import styled from "styled-components";
import PageWrap from "PageWrap";
import ScrollAnimation from "react-animate-on-scroll";
import DiscographyItem from "./DiscographyItem";
import discography from "Utils/discography";

const Page = styled(PageWrap)`
  background-color: #7f766b;
  color: #fff;
`;

const ScrollAnimationAlbum = styled(ScrollAnimation)`
  margin-bottom: 100px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

const Discography = () => {
  return (
    <Page
      logoColor="white"
      pageTitleOptions={{
        title: "DISCOGRAPHY",
      }}
    >
      {discography.map((data, idx) => (
        <ScrollAnimationAlbum
          key={idx}
          animateIn="fadeInUp"
          duration={1.2}
          animateOnce
        >
          <DiscographyItem key={idx} {...data} />
        </ScrollAnimationAlbum>
      ))}
    </Page>
  );
};

export default Discography;
