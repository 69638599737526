import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
//Pending: maybe use HashRouter and a child theme to build this page
import {
  HashRouter as Router,
  Switch,
  Route,
  withRouter,
  Redirect,
} from "react-router-dom";
import GA from "Utils/GoogleAnalytics";

import { TransitionGroup, CSSTransition } from "react-transition-group";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.min.css";
import "Assets/css/app.css";
import classnames from "classnames";
import Loading from "Loading";
import Home from "Home";
import IndexPage from "IndexPage";
import Biography from "Biography";
import Discography from "Discography";
import Store from "Store";
import Blog from "Blog";
import Shows from "Shows";
import Band from "Band";

// https://medium.com/@ger86/react-how-to-animate-transitions-between-react-router-routes-7f9cb7f5636a
const AnimateSwitchWrap = styled.div`
  position: relative;
  .slide-enter,
  .slide-exit {
    transition: transform 800ms ease-out;
  }

  .slide-enter {
    transform: translateX(100%);
  }

  .slide-enter.slide-enter-active {
    transform: translateX(0%);
  }

  .slide-exit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateX(0%);
  }

  .slide-exit-active {
    transform: translateX(-100%);
  }
`;

const GlobalStyle = createGlobalStyle`
  body {
    color: #fff;
    background-color: ${(props) => {
      switch (props.path) {
        case "/":
        case "/404":
          return "#989284";
        case "/indexPage":
          return "#5e92bf";
        case "/biography":
          return "#755083";
        case "/band":
          return "#e66a44";
        case "/store":
          return "#f2d64b";
        case "/discography":
        case props.path.includes("/blog"):
          return "#7f766b";
        case "/shows":
          return "#859946";
        default:
          return "#fff";
      }
    }}
  }
`;

const AnimatedSwitch = withRouter(({ location }) => {
  let classNames = "slide";
  // const prevLocation = usePrevious(location);

  // //prevent css transition if url path root matches
  // const prevPathname = get(prevLocation, "pathname", "/");
  // const pathname = get(location, "pathname", "/");
  // const prevPathnameArr = prevPathname.split("/");
  // const pathnameArr = pathname.split("/");

  // // if blog == blog, don't animate
  // if(
  //   prevPathnameArr.length >= 2 && pathnameArr.length >= 2 &&
  //   prevPathnameArr[1] === pathnameArr[1]
  // ){
  //   classNames = ""; // no custom class animation
  // };
  return (
    <AnimateSwitchWrap>
      <GlobalStyle path={location.pathname} />
      <TransitionGroup>
        <CSSTransition
          // key={location.key}
          key={location.pathname} //to work in hashRouter
          classNames={classNames}
          timeout={800}
          onEntering={() => window.scrollTo(0, 0)}
        >
          <Switch location={location}>
            <Route exact path="/" component={Home} />
            <Route path="/indexPage" component={IndexPage} />
            <Route path="/biography" component={Biography} />
            <Route path="/band" component={Band} />
            <Route path="/discography" component={Discography} />
            <Route path="/store" component={Store} />
            {/* <Route path="/storeRefresh" component={StoreRefresh} /> */}
            <Route path="/blog/:page?" component={Blog} />
            <Route path="/shows" component={Shows} />
            <Route path="/404">
              <Home pageNotFound />
            </Route>
            <Redirect to="/404" />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </AnimateSwitchWrap>
  );
});

function App() {
  const [fadeOutLoading, setFadeOutLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(true);

  const loadingIn = 1500;
  setTimeout(() => {
    setFadeOutLoading(true);
    setTimeout(() => {
      setShowLoading(false);
    }, 1000);
  }, loadingIn + 500);

  return (
    <>
      {showLoading && (
        <Loading
          loadIn={loadingIn}
          className={classnames("animated", {
            fadeOutUp: fadeOutLoading,
          })}
        />
      )}

      <Router>
        {GA.init() && <GA.RouteTracker />}
        <AnimatedSwitch />
      </Router>
    </>
  );
}

export default App;
