import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styled from "styled-components";

const Button = styled.a`
  text-transform: uppercase;
  background-color: transparent;
  border: 5px solid #ddd5bf;
  color: #ddd5bf;
  border-radius: 0;
  font-family: "Teko", sans-serif;
  font-weight: bold;
  font-size: 22px;
  outline: none;
  padding-top: 0px;
  padding-bottom: 0px;
  &:hover {
    color: #36302f;
  }
  &:active {
    color: #36302f;
    background-color: #ddd5bf;
    border-color: #ddd5bf;
  }

  &.btn-dark {
    border-color: #36302f;
    color: #36302f;
    &:hover {
      color: #ddd5bf;
    }
    &:active {
      color: #ddd5bf;
      background-color: #36302f;
      border-color: #36302f;
    }
  }
`;

const RustyEyeButtonLink = ({
  className,
  large,
  block,
  dark,
  href,
  onClick,
  target,
  text,
}) => {
  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <Button
      className={classnames(
        "btn",
        { "btn-lg": large },
        { "btn-block": block },
        { "btn-dark": dark },
        className
      )}
      href={href}
      target={target}
      onClick={handleClick}
    >
      {text}
    </Button>
  );
};

RustyEyeButtonLink.propTypes = {
  className: PropTypes.string,
  large: PropTypes.bool,
  block: PropTypes.bool,
  dark: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  text: PropTypes.string.isRequired,
};

export default RustyEyeButtonLink;
