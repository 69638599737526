import React from "react";
import Typed from "react-typed";
import styled from "styled-components";
import breakpoint from "Utils/breakpoint";
import quotes from "Utils/quotes";

// pending: need to figure out how to use the template font
const Blockquote = styled.blockquote`
  font-size: 12px;
  transition: all 0.5s ease-in-out;
  @media (min-width: ${breakpoint.sm}) {
    font-size: 16px;
  }
  .quote {
    font-size: 3em;
    line-height: 1em;
  }
  .author {
    font-size: 2em;
    line-height: 1em;
    margin-top: 5px;
    color: #36302f;
    font-style: italic;
  }
`;

const QuoteList = styled.div``;

/**
 * List of quotes
 */
const Quotes = () => {
  let strings = quotes.map(
    (quote) => `
    <div class='quote'>"${quote.text}" </div>
    <div class='author'>- ${quote.author} </div>
  `
  );

  //randomize
  strings = strings.sort(function () {
    return 0.5 - Math.random();
  });

  return (
    <QuoteList className="fontTeko">
      <Blockquote>
        <Typed
          strings={strings}
          typeSpeed={20}
          backDelay={3000}
          backSpeed={10}
          showCursor={false}
          // onComplete={() => setShowAuthor(true)}
          loop
        />
      </Blockquote>
    </QuoteList>
  );
};

export default Quotes;
