import React from "react";
import styled from "styled-components";
import classnames from "classnames";
import PropTypes from "prop-types";
import HandScalpel from "Assets/img/hand_scalpel_trasparent.png";
import RustyEyeBlack from "Assets/img/rustyEye_black.svg";
import RustyEyeWhite from "Assets/img/rustyEye_white.svg";
import breakpoint from "Utils/breakpoint";

const PageTitleContent = styled.div`
  font-family: "Wolf-Brothers", sans-serif;
  font-size: 16px;
  position: relative;

  h1 {
    font-size: 3em;
    line-height: 0.7; /** prevents extra margin */
    margin: 0px;
    padding: 0px;
  }

  .handScalpel {
    width: 8.5em;
    position: absolute;
    bottom: -10%;
    right: 0px;
    user-select: none;
    &.withLogo {
      bottom: 15%;
    }
  }

  @media (min-width: ${breakpoint.md}) {
    font-size: 30px;
  }

  @media (min-width: ${breakpoint.lg}) {
    font-size: 50px;
  }

  .rustyEyeLogo {
    width: auto;
    height: 5em;
    display: inline-block;
    user-select: none;

    @media (min-width: ${breakpoint.sm}) {
      height: 6em;
    }

    @media (min-width: ${breakpoint.md}) {
      height: 5em;
    }

    @media (min-width: ${breakpoint.lg}) {
      height: 3.5em;
    }
    @media (min-width: ${breakpoint.xl}) {
      height: 4.5em;
    }
  }
`;

const PageTitle = ({ title, textAlign, logoColor, className }) => {
  return (
    <PageTitleContent
      className={classnames(
        className,
        { "text-right": textAlign === "right" },
        { "text-left": textAlign === "left" },
        { "text-center": textAlign === "center" || !textAlign }
      )}
    >
      {title && <h1>{title}</h1>}

      {logoColor && (
        <img
          className="rustyEyeLogo"
          src={logoColor === "white" ? RustyEyeWhite : RustyEyeBlack}
          alt="Rusty Eye"
        />
      )}

      <img
        className={classnames("handScalpel", { withLogo: logoColor })}
        src={HandScalpel}
        alt="Hand Scalpel"
      />
    </PageTitleContent>
  );
};

export const PageTitleProps = {
  title: PropTypes.string,
  className: PropTypes.string,
  logoColor: PropTypes.string,
  textAlign: PropTypes.oneOf(["left", "right", "center"]),
};

PageTitle.propTypes = PageTitleProps;

export default PageTitle;
