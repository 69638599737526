import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import classnames from "classnames";
import breakpoint from "Utils/breakpoint";
//logos
import SpotifyLogo from "Assets/img/disc/logos/spotify_logo.svg";
import SpotifyIcon from "Assets/img/disc/logos/spotify_icon.svg";
import AppleMusicLogo from "Assets/img/disc/logos/appleMusic_logo.svg";
import AppleMusicIcon from "Assets/img/disc/logos/appleMusic_icon.svg";
import BandCampLogo from "Assets/img/disc/logos/bandcamp_logo.svg";
import BandCampIcon from "Assets/img/disc/logos/bandcamp_icon.svg";
import SoundCloudLogo from "Assets/img/disc/logos/soundCloud_logo.svg";
import SoundCloudIcon from "Assets/img/disc/logos/soundCloud_icon.svg";

const DiscographyListenOnContent = styled.div`
  width: 100%;
  margin: 30px auto;
  display: flex;
  align-items: center; /* Vertical */
  justify-content: center; /* Horizontal */
  justify-content: space-between;

  .listenTitle {
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
    color: #36302f;
  }
  .listen {
    display: inline-block;
    a {
      display: inline-block;
    }
    img {
      width: 100%;
      display: block;
      &.icon {
        width: 40px;
      }
    }
  }

  @media (min-width: ${breakpoint.lg}) {
    display: block;
    width: 70%;
    margin: 0px;

    .listen {
      display: block;
      max-width: 300px;
      margin-bottom: 20px;
      img {
        min-height: 35px;
      }
    }
    .listenTitle {
      display: block;
      margin-bottom: 10px;
    }
  }
`;

const ListenLink = ({ title, link, logo, icon }) => {
  const handleLinkClick = (e) => {
    e.preventDefault();

    if (link === "#") {
      alert("Stay Tuned. Pending Link");
      return false;
    }
    window.open(link, "_blank");
  };

  return (
    <div className="listen">
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleLinkClick}
      >
        <img
          className="d-none d-lg-block"
          src={logo}
          alt={title}
          title={title}
        />
        <img
          className="icon d-block d-lg-none"
          src={icon}
          alt={title}
          title={title}
        />
      </a>
    </div>
  );
};

const DiscographyListenOn = ({ listenOn, className }) => {
  return (
    <DiscographyListenOnContent className={classnames(className)}>
      <div className="listenTitle fontTeko">Listen:</div>
      {listenOn && listenOn.spotify && (
        <ListenLink
          title="Spotify"
          link={listenOn.spotify}
          logo={SpotifyLogo}
          icon={SpotifyIcon}
        />
      )}
      {listenOn && listenOn.appleMusic && (
        <ListenLink
          title="Apple Music"
          link={listenOn.appleMusic}
          logo={AppleMusicLogo}
          icon={AppleMusicIcon}
        />
      )}
      {listenOn && listenOn.bandCamp && (
        <ListenLink
          title="Band Camp"
          link={listenOn.bandCamp}
          logo={BandCampLogo}
          icon={BandCampIcon}
        />
      )}
      {listenOn && listenOn.soundCloud && (
        <ListenLink
          title="SoundCloud"
          link={listenOn.soundCloud}
          logo={SoundCloudLogo}
          icon={SoundCloudIcon}
        />
      )}
    </DiscographyListenOnContent>
  );
};

DiscographyListenOn.propTypes = {
  listenOn: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default DiscographyListenOn;
