import includes from "lodash/includes";
/**
 * Returns the API url depending on development environment
 * @param {*} custom custom api path made in functions.php
 */
export const getAPIUrl = (custom) => {
  const origin = window.location.origin;

  let path = "wp-json/wp/v2";
  if (custom) {
    path = "wp-json";
  }

  if (
    includes(origin, "rusty-eye.lndo.site") ||
    includes(origin, "localhost")
  ) {
    return `//rusty-eye.lndo.site/${path}`;
  } else {
    return `//rustyeye.com/${path}`;
  }
};
