import React, { useEffect } from "react";
import styled from "styled-components";
import PageWrap from "PageWrap";
import breakpoint from "Utils/breakpoint";

const Page = styled(PageWrap)`
  background-color: #859946;
  color: #fff;

  .bit-widget {
    /* header links */
    .bit-top-track-button {
      font-size: 1em;
      padding: 0px;
      margin-bottom: 10px;
      display: inline-block;
      @media (min-width: ${breakpoint.md}) {
        font-size: 1.5em;
      }
    }
    .bit-nav-bar-container {
      margin-bottom: 10px;
      padding: 0px;
      .bit-event-list-title {
        font-size: 1em;
        font-weight: bold;
        border: 3px solid #f2d64b;
        display: inline-block;
        padding: 5px 10px;
        margin-right: 20px;
        background: #f2d64b;
        color: #859946;
        &.bit-clickable {
          text-decoration: none !important;
          background: transparent;
          color: #f2d64b;
        }
      }
      @media (min-width: ${breakpoint.md}) {
        .bit-event-list-title {
          font-size: 1.5em;
          padding: 5px 20px;
        }
      }
    }
    /* logo colors */
    .bit-logo-container {
      svg {
        g {
          opacity: 1 !important;
        }
        path {
          fill: #fff !important;
        }
      }
    }

    /* list of events details */
    .bit-event {
      border-top: 3px solid #fff;
      font-size: 1.2em;
      padding-right: 0px !important;
      padding-left: 0px !important;
      .bit-details {
        & > div {
          margin-bottom: 5px;
        }
      }
      .bit-venue {
        font-weight: lighter;
      }
      @media (min-width: ${breakpoint.md}) {
        font-size: 1.5em;
        .bit-details {
          & > div {
            margin-bottom: 10px;
          }
        }
      }
    }

    /* buttons */
    .bit-rsvp {
      border: 3px solid #f2d64b;
    }

    /* events containers */
    .bit-upcoming-events,
    .bit-past-events {
      margin-right: 0px !important;
      margin-left: 0px !important;
    }

    /* no events container */
    .bit-no-dates-container {
      border-top: 3px solid #fff;
      margin: 0px;
      padding: 80px 0px;
      .bit-no-dates-title {
        font-size: 1.3em;
        font-weight: bold;
      }
      .bit-track-button {
        font-size: 1.3em;
        background: transparent;
        border: 3px solid #f2d64b;
        color: #f2d64b;
      }
      @media (min-width: ${breakpoint.md}) {
        .bit-no-dates-title {
          font-size: 1.8em;
        }
        .bit-track-button {
          width: auto;
          padding: 5px 20px;
          font-size: 1.6em;
        }
      }
    }
  } /** end */
`;

const Shows = () => {
  //append widget script
  const appendScriptTag = () => {
    const script = document.createElement("script");
    script.id = "bandsintown-script";
    script.src = "https://widget.bandsintown.com/main.min.js";
    script.async = true;
    document.body.appendChild(script);
  };

  useEffect(() => {
    appendScriptTag();

    return () => {
      //remove extra script
      const script = document.getElementById("bandsintown-script");
      if (script) {
        script.remove();
      }
    };
  }, []);

  return (
    <Page
      logoColor="white"
      pageTitleOptions={{
        title: "SHOWS",
      }}
    >
      <div className="fontTeko">
        <a
          href="/#/shows"
          className="bit-widget-initializer"
          data-artist-name="Rusty Eye"
          data-display-past-dates="true"
          data-text-color="#fff"
          data-link-color="#F2D64B"
          data-background-color="#859946"
          data-display-details="true"
          data-popup-background-color="#ffffff"
          data-link-text-color="#FFFFFF"
          data-separator-color="#FFFFFF"
          data-language="en"
          data-font="Teko"
          data-display-local-dates="false"
          data-auto-style="false"
          data-display-lineup="false"
          data-display-play-my-city="true"
          data-display-limit="15"
          data-display-start-time="false"
        >
          ...
        </a>
      </div>
    </Page>
  );
};

export default Shows;
