import Page from "./Page";
import styled from "styled-components";
import breakpoint from "Utils/breakpoint";

export const PageContent = styled.div`
  /** rules all font sizes */
  font-family: "Teko", sans-serif;
  font-size: 24px;
  line-height: 1.3;
  @media (min-width: ${breakpoint.sm}) {
    font-size: 30px;
  }
  @media (min-width: ${breakpoint.md}) {
    font-size: 35px;
  }
  p {
    font-weight: lighter;
    span {
      color: #f2d64b;
    }
    b {
      font-weight: 700;
    }
    &.small {
      font-size: 14px;
      margin: 0px;
      margin-bottom: 10px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }

  .row {
    margin-top: 1em;
    margin-bottom: 1em;
    &:first-child {
      margin-top: 0px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }

  .col-12 {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0px; /** PENDING: check if needed, rows have padding */
    }
    @media (min-width: ${breakpoint.sm}) {
      margin-bottom: 0px;
    }
  }
`;

export { Page as default };
