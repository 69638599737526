/** home page quotes*/
const quotes = [
  {
    text: "When these three are on, it’s goddamned impressive",
    author: "Ryan Ogle, blabbermouth.net",
  },
  {
    text:
      "Soon we’ll be talking about the emergence of a masterpiece of international recognition",
    author: "Juan Martínez, El Sercho [Mexico]",
  },
  {
    text:
      "Those bastards even managed to get the one and only Claudio Simonetti on some of their tracks!",
    author: "Rurik Sallé, Hard Rock [France]",
  },
  {
    text:
      "Rusty Eye pays homage to the past while maintaining its own identity",
    author: "Buick Mckane, Metal underground.com",
  },
  {
    text: "RUSTY EYE indulges in horror-obsession with a technical thrash edge",
    author: "George Pacheco, The examiner.com",
  },
  {
    text: "Rusty Eye take horror/cinematic metal to new levels on Possessor",
    author: "David E. Gehlke, blistering.com",
  },
  {
    text:
      "A complex alloy of virtually every extreme music form, Rusty Eye gives new meaning to diversity",
    author: "Keith Carman, Exclaim! [Canada]",
  },
  {
    text:
      "Rusty Eye are one of the most talented up and coming bands I have had the pleasure of hearing this past decade",
    author: "Rob “Bubbs” Harris, Metal Exiles",
  },
  {
    text:
      "Surprises, landmines of musical prowess, lie in wait amongst the album’s virtual grooves",
    author: "Gaz E, Über Röck",
  },
  {
    text:
      "I wish more bands would spend the time to develop creative ideas like this to market their music on the web",
    author: "Vince Neilstein, Metal Sucks",
  },
  {
    text:
      "This emission by a three-piece out of Hollywood, California will be by some considerable margin the most unique sound you’ll hear anytime soon",
    author: "Mark Eglinton, Thrash Hits [UK]",
  },
  {
    text:
      "Horror Rockers Rusty Eye showcase “Possessor” with new video innovation",
    author: "Revolver Magazine",
  },
  {
    text:
      "It thrashes, it rocks, it progs and can stare down a zombie at 100 yards, but still sounds like something you’ve never heard before",
    author: "Calum Harvey, Zero Tolerance [UK]",
  },
  {
    text: "The Magic of Metal is Back!",
    author: "Metal Observer",
  },
  {
    text: "Rusty Eye has their own identity within the metal genre",
    author: "Cosmos Gaming",
  },
  {
    text: "Any true horror and metal fan MUST check these guys out",
    author: "Gregory Burkart, fear.net",
  },
  {
    text:
      "A rarity in rock and metal bands — to have a female ‘manning the kit,’ so to speak",
    author: "Amy Sciarretto, Noisecreep",
  },
  {
    text:
      "Boasting one of the most bitchin’ female drummer’s I’ve seen in a metal outfit, the trio definitely rocked the place.",
    author: "Rob Galluzzo, Shock Till You Drop",
  },
];

export default quotes;
