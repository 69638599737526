import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import BrainBg from "Assets/img/brain_bg.jpg";
import HandScalpel from "Assets/img/hand_scalpel.png";
import PropTypes from "prop-types";

const LoadingBarContainer = styled.div`
  width: 100%;
  max-width: 80%;
  height: 30px;
  background-color: #ddd5bf;
  border: 3px solid #fff;
  margin: auto;
  position: relative;
  margin-top: 80px;

  @media (min-width: 768px) {
    margin-top: 40px;
    max-width: 465px;
    height: 65px;

    &:before {
      content: "0";
      position: absolute;
      left: -5px;
      bottom: -30px;
      font-size: 16px;
    }

    &:after {
      content: "100";
      position: absolute;
      right: -5px;
      bottom: -30px;
      font-size: 16px;
    }
  }
`;

const Bar = styled.div`
  max-width: 100%;
  width: ${(props) => props.percentage || 1}%;
  height: 100%;
  position: relative;
  overflow: hidden;
  transition: width 0.2s ease-in-out;

  img {
    width: 500px;
    position: relative;
    top: -200px;
  }
`;

const Hand = styled.img`
  width: 200px;
  z-index: 10;
  position: absolute;
  bottom: 0px;
  left: ${(props) => props.percentage || 1}%;
  transition: left 0.2s ease-in-out;
  animation-duration: ${(props) => props.shakeDuration}s;

  @media (min-width: 768px) {
    width: 550px;
  }
`;

const LoadingBar = ({ percentage = 1, loadIn = 1500 }) => {
  const [percentageNow, setPercentageNow] = useState(percentage);

  let interval = useRef(false);

  useEffect(() => {
    if (typeof loadIn !== "number") {
      return;
    }

    const loadInInterval = loadIn / 10;

    interval.current = setInterval(() => {
      setPercentageNow((percentageNow) => percentageNow + 10);
    }, loadInInterval);

    return () => clearInterval(interval.current);
  }, [loadIn, interval]);

  useEffect(() => {
    if (percentageNow >= 100) {
      clearInterval(interval.current);
    }
  }, [interval, percentageNow]);

  return (
    <LoadingBarContainer>
      <Hand
        src={HandScalpel}
        alt="Scalpel Hand"
        percentage={percentageNow}
        shakeDuration={loadIn}
      />
      <Bar percentage={percentageNow}>
        <img src={BrainBg} alt="Brain" />
      </Bar>
    </LoadingBarContainer>
  );
};

LoadingBar.propTypes = {
  loadIn: PropTypes.number,
  percentage: PropTypes.number,
};

export default LoadingBar;
