/** discography data */

//covers
import DissectingShadows from "Assets/img/disc/covers/01-Dissecting.png";
import Permanent from "Assets/img/disc/covers/permanent.png";
import CantWaitToGoToHell from "Assets/img/disc/covers/cant-wait.png";

import RNR_over_again from "Assets/img/disc/covers/02-rnr-over-again.png";
import Saca from "Assets/img/disc/covers/03-Saca.png";
import Possessor from "Assets/img/disc/covers/04-Possessor.png";
import Stedhal from "Assets/img/disc/covers/05-stedhal.png";
import RNRoll from "Assets/img/disc/covers/06-rnroll.png";
import Joint from "Assets/img/disc/covers/07-joint.png";
import Rainbow from "Assets/img/disc/covers/08-bow.png";
import SlayBelles from "Assets/img/disc/covers/09-slay-belles.png";
import NexTrip from "Assets/img/disc/covers/10-nextrip.png";
import MrCannibal from "Assets/img/disc/covers/11-mr-cannibal.png";
import Cryo from "Assets/img/disc/covers/12-cryo.png";

//albums
import Album2001 from "Assets/img/disc/labels/2001_album.svg";
import EP2005 from "Assets/img/disc/labels/2005_ep.svg";
import Album2006 from "Assets/img/disc/labels/2006_album.svg";
import Live2006 from "Assets/img/disc/labels/2006_live.svg";
import Album2009 from "Assets/img/disc/labels/2009_album.svg";
import Live2010 from "Assets/img/disc/labels/2010_live.svg";
import Album2014 from "Assets/img/disc/labels/2014_album.svg";
import Single2014 from "Assets/img/disc/labels/2014_single.svg";
import Single2018 from "Assets/img/disc/labels/2018_single.svg";
import EP2019 from "Assets/img/disc/labels/2019_ep.svg";
import Album2020 from "Assets/img/disc/labels/2020_album.svg";
import Single2020 from "Assets/img/disc/labels/2020_single.svg";

const discography = [
  {
    title: "Dissecting Shadows",
    cover: DissectingShadows,
    yearLabel: {
      img: Album2020,
      title: "2020 Album",
    },
    labels: {
      album12: true,
      album10: false,
      cd: true,
      tape: true,
      digital: true,
    },
    listenOn: {
      spotify:
        "https://open.spotify.com/album/5ovCuj6htVOvZWZDdDhzWV?si=ZEo9M-NYQQmJV32YHm8QhA",
      appleMusic:
        "https://music.apple.com/us/album/dissecting-shadows/1526496053",
      bandCamp: "https://rustyeye.bandcamp.com/album/dissecting-shadows-2",
      soundCloud: "https://soundcloud.com/rustyeye/sets/dissecting-shadows",
    },
    iframe: `<iframe src="https://open.spotify.com/embed/album/5ovCuj6htVOvZWZDdDhzWV" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`,
    buyRecord:
      "https://store.rustyeye.com/product/dissecting-shadows-12-vinyl-pre-order",
  },
  {
    title: "This is Permanent",
    cover: Permanent,
    single: true,
    yearLabel: {
      img: Single2020,
      title: "2020 Single",
    },
    labels: {
      album12: false,
      album10: false,
      cd: false,
      tape: false,
      digital: true,
    },
    listenOn: {
      spotify:
        "https://open.spotify.com/track/7thoKnBZYjVLQ3mVAN6bxK?si=syoD5TgeQzqT3E6Sm0CW_g",
      appleMusic:
        "https://music.apple.com/us/album/this-is-permanent-single/1526751504",
      bandCamp: "https://rustyeye.bandcamp.com/album/this-is-permanent-single",
      soundCloud: "",
    },
    iframe: `<iframe src="https://open.spotify.com/embed/album/4WcmBj76dqGJODZ5VZ3UL9" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`,
    buyRecord: "",
  },
  {
    title: "Can't wait to go to hell",
    cover: CantWaitToGoToHell,
    single: true,
    yearLabel: {
      img: Single2020,
      title: "2020 Single",
    },
    labels: {
      album12: false,
      album10: false,
      cd: false,
      tape: false,
      digital: true,
    },
    listenOn: {
      spotify:
        "https://open.spotify.com/album/7lW3rBXflBXwXGIc7V947S?si=1u0qdI7zRL2XbYdmzioaBA",
      appleMusic:
        "https://music.apple.com/us/album/cant-wait-to-go-to-hell-single/1529334375",
      bandCamp: "https://rustyeye.bandcamp.com/track/cant-wait-to-go-to-hell-2",
      soundCloud: "",
    },
    iframe: `<iframe src="https://open.spotify.com/embed/album/7lW3rBXflBXwXGIc7V947S" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`,
    buyRecord: "",
  },
  {
    title: "Rust n' Roll Over Again",
    cover: RNR_over_again,
    yearLabel: {
      img: EP2019,
      title: "2020 EP",
    },
    labels: {
      album12: false,
      album10: true,
      cd: true,
      tape: false,
      digital: true,
    },
    listenOn: {
      spotify:
        "https://open.spotify.com/album/3xVxmz8dfvYdJ8GpjMhLUh?si=YV71MFqkQgOwFLcb0AaJyg",
      appleMusic:
        "https://music.apple.com/us/album/rust-n-roll-over-again-ep/1468934575",
      bandCamp: "https://rustyeye.bandcamp.com/album/rust-n-roll-over-again",
      soundCloud: "https://soundcloud.com/rustyeye/sets/rust-n-roll-over-again",
    },
    iframe: `<iframe src="https://open.spotify.com/embed/album/3xVxmz8dfvYdJ8GpjMhLUh" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`,
    buyRecord:
      "https://store.rustyeye.com/product/rust-n-roll-over-again-ep-2019-10-viinyl",
  },
  {
    title: "Slay Belles",
    cover: SlayBelles,
    single: true,
    yearLabel: {
      img: Single2018,
      title: "2018 Single",
    },
    labels: {
      album12: false,
      album10: false,
      cd: false,
      tape: false,
      digital: true,
    },
    listenOn: {
      spotify:
        "https://open.spotify.com/album/2bM1O6PlBektQAtUwzYbG7?si=yAbTgcZAQD2-WOV7rM2jCQ",
      appleMusic:
        "https://music.apple.com/us/album/hey-santa-single/1476354606",
      bandCamp: "https://rustyeye.bandcamp.com/track/hey-santa",
      soundCloud: "https://soundcloud.com/rustyeye/hey-santa",
    },
    iframe: `<iframe src="https://open.spotify.com/embed/album/2bM1O6PlBektQAtUwzYbG7" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`,
    buyRecord: "",
  },
  {
    title: "NexTrip",
    cover: NexTrip,
    single: true,
    yearLabel: {
      img: Single2014,
      title: "2014 Single",
    },
    labels: {
      album12: false,
      album10: false,
      cd: false,
      tape: false,
      digital: true,
    },
    listenOn: {
      spotify:
        "https://open.spotify.com/album/4HMkJn5U01lKSb8cb8DLZX?si=QQYMFTdGSGK4h-6IVMfSGw",
      appleMusic: "https://music.apple.com/us/album/nextrip-single/1512098380",
      bandCamp: "",
      soundCloud: "",
    },
    iframe: `<iframe src="https://open.spotify.com/embed/album/4HMkJn5U01lKSb8cb8DLZX" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`,
    buyRecord: "",
  },
  {
    title: "Mr Cannibal Remake Single",
    single: true,
    cover: MrCannibal,
    yearLabel: {
      img: Single2014,
      title: "2014 Single",
    },
    labels: {
      album12: false,
      album10: false,
      cd: false,
      tape: false,
      digital: true,
    },
    listenOn: {
      spotify:
        "https://open.spotify.com/album/6ikqel320wv4Q86uaRc4XQ?si=33Jv7pvRSPWbUvZbavVnSg",
      appleMusic:
        "https://music.apple.com/us/album/mr-cannibal-remake-single/1481019118      ",
      bandCamp: "https://rustyeye.bandcamp.com/album/mr-cannibal-remake-single",
      soundCloud: " https://soundcloud.com/rustyeye/mr-cannibal-remake",
    },
    iframe: `<iframe src="https://open.spotify.com/embed/album/6ikqel320wv4Q86uaRc4XQ" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`,
    buyRecord: "",
  },
  {
    title: "Saca el Cobre",
    cover: Saca,
    yearLabel: {
      img: Album2014,
      title: "2014 Albulm",
    },
    labels: {
      album12: false,
      album10: false,
      cd: true,
      tape: false,
      digital: true,
    },
    listenOn: {
      spotify:
        "https://open.spotify.com/album/6RG3MdjAsNxsZGlzYv3Ddy?si=7PUkiv6ZQxOsWo_1wLjz9A",
      appleMusic: "https://music.apple.com/us/album/saca-el-cobre/1481302840",
      bandCamp: "https://rustyeye.bandcamp.com/album/saca-el-cobre",
      soundCloud: "https://soundcloud.com/rustyeye/sets/saca-el-cobr",
    },
    iframe: `<iframe src="https://open.spotify.com/embed/album/6RG3MdjAsNxsZGlzYv3Ddy" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`,
    buyRecord: " https://store.rustyeye.com/product/saca-el-cobre-2014-cd",
  },
  {
    title: "Live at the Rainbow",
    cover: Rainbow,
    yearLabel: {
      img: Live2010,
      title: "2010 Live",
    },
    labels: {
      album12: false,
      album10: false,
      cd: true,
      tape: false,
      digital: true,
    },
    listenOn: {
      spotify:
        "https://open.spotify.com/album/2azBGerSRJ4hEuQoLImo2d?si=5D-hHaZISxmg8ReMNdWu0w",
      appleMusic:
        " https://music.apple.com/us/album/live-at-the-rainbow-mmx/1499588691",
      bandCamp: "https://rustyeye.bandcamp.com/album/live-at-the-rainbow-mmx",
      soundCloud: "",
    },
    iframe: `<iframe src="https://open.spotify.com/embed/album/2azBGerSRJ4hEuQoLImo2d" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`,
    buyRecord:
      "https://store.rustyeye.com/product/live-at-the-rainbow-mmx-2010-cd",
  },
  {
    title: "Possesor",
    cover: Possessor,
    yearLabel: {
      img: Album2009,
      title: "2009 Album",
    },
    labels: {
      album12: false,
      album10: true,
      cd: true,
      tape: true,
      digital: true,
    },
    listenOn: {
      spotify:
        "https://open.spotify.com/album/2YUjnmJd8cqeUgfNWzn6IC?si=nPs0_cJiSJGhEJ2xwjsVzg",
      appleMusic: "https://music.apple.com/us/album/possessor/1489405979",
      bandCamp: "https://rustyeye.bandcamp.com/album/possessor",
      soundCloud: "https://soundcloud.com/rustyeye/sets/possessor",
    },
    iframe: `<iframe src="https://open.spotify.com/embed/album/2YUjnmJd8cqeUgfNWzn6IC" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`,
    buyRecord: "https://store.rustyeye.com/product/possessor-2009-cd",
  },
  {
    title: "Stendhal Syndrome",
    cover: Stedhal,
    yearLabel: {
      img: Album2006,
      title: "2006 Album",
    },
    labels: {
      album12: false,
      album10: false,
      cd: true,
      tape: false,
      digital: true,
    },
    listenOn: {
      spotify:
        "https://open.spotify.com/album/5n4vIWSJKNABkfWO8zaATN?si=0jLgRflnSM6_JGpQQWLK7w",
      appleMusic:
        "https://music.apple.com/us/album/stendhal-syndrome/1491279808",
      bandCamp: "https://rustyeye.bandcamp.com/album/stendhal-syndrome",
      soundCloud: "",
    },
    iframe: `<iframe src="https://open.spotify.com/embed/album/5n4vIWSJKNABkfWO8zaATN" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`,
    buyRecord: "https://store.rustyeye.com/product/stendhal-syndrome-2006-cd",
  },
  {
    title: "Live at the Joint",
    cover: Joint,
    yearLabel: {
      img: Live2006,
      title: "2006 Live",
    },
    labels: {
      album12: false,
      album10: false,
      cd: true,
      tape: false,
      digital: true,
    },
    listenOn: {
      spotify:
        "https://open.spotify.com/album/4JKkWz1aydG7cLxpzgArYy?si=8uHRSpoGQzKHNnOB_GPF7A",
      appleMusic:
        " https://music.apple.com/us/album/live-at-the-joint-mmvi/1499576428",
      bandCamp: "https://rustyeye.bandcamp.com/album/live-at-the-joint-mmvi",
      soundCloud: "",
    },
    iframe: `<iframe src="https://open.spotify.com/embed/album/4JKkWz1aydG7cLxpzgArYy" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`,
    buyRecord:
      "https://store.rustyeye.com/product/live-at-the-joint-mmvi-2006-cd",
  },
  {
    title: "Cryogenic",
    cover: Cryo,
    yearLabel: {
      img: EP2005,
      title: "2005 EP",
    },
    labels: {
      album12: false,
      album10: false,
      cd: true,
      tape: false,
      digital: true,
    },
    listenOn: {
      spotify:
        "https://open.spotify.com/album/3i1jm87O9J2GpoqRqbiaHs?si=0NrUUVFmSVy46bQEaiSHUA",
      appleMusic:
        "https://music.apple.com/us/album/cryogenic-single/1499267263",
      bandCamp: "",
      soundCloud: "",
    },
    iframe: `<iframe src="https://open.spotify.com/embed/album/3i1jm87O9J2GpoqRqbiaHs" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`,
    buyRecord: "",
  },
  {
    title: "Rust n' Roll",
    cover: RNRoll,
    yearLabel: {
      img: Album2001,
      title: "2001 Album",
    },
    labels: {
      album12: false,
      album10: false,
      cd: true,
      tape: false,
      digital: true,
    },
    listenOn: {
      spotify:
        "https://open.spotify.com/album/3hiM7UDVn1P6Wga0DRS55J?si=uJP9yRGvR5mu6dDIhjlxJQ",
      appleMusic: "https://music.apple.com/us/album/rust-n-roll/1499571116",
      bandCamp: "https://rustyeye.bandcamp.com/album/rust-n-roll",
      soundCloud: "",
    },
    iframe: `<iframe src="https://open.spotify.com/embed/album/3hiM7UDVn1P6Wga0DRS55J" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`,
    buyRecord: "https://store.rustyeye.com/product/rust-n-roll-2001-cd",
  },
  // {
  //   title: "",
  //   cover: "",
  //   yearLabel: {
  //     img: "",
  //     title: "",
  //   },
  //   labels: {
  //     album12: false,
  //     album10: false,
  //     cd: false,
  //     tape: false,
  //     digital: false,
  //   },
  //   listenOn: {
  //     spotify: "",
  //     appleMusic: "",
  //     bandCamp: "",
  //     soundCloud: "",
  //   },
  //   iframe: ``,
  //   buyRecord: "",
  // },
];

export default discography;
