import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styled from "styled-components";
import IndexSimpleArrowLeft from "Assets/img/index_simpleArrow_left.svg";
import HomeSimpleArrowLeft from "Assets/img/home_simpleArrow_left.svg";
import { useHistory } from "react-router-dom";

const IndexLinkImg = styled.img`
  width: 120px;
  user-select: none;
  cursor: pointer;
`;

const IndexLink = ({ type = "index", className, title, path = "/" }) => {
  const history = useHistory();
  let ImageSrc;

  switch (type) {
    case "index":
      ImageSrc = IndexSimpleArrowLeft;
      break;
    case "home":
      ImageSrc = HomeSimpleArrowLeft;
      break;
    default:
      ImageSrc = "n/a";
      break;
  }

  return (
    <IndexLinkImg
      className={classnames(className)}
      src={ImageSrc}
      alt={title}
      title={title}
      onClick={() => {
        history.push(path);
      }}
    />
  );
};

IndexLink.propTypes = {
  type: PropTypes.oneOf(["index", "home"]),
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default IndexLink;
