import App from "./App";

/** if there is text, the button or link will appear on the location pathname */
const AppNavOptions = {
  "/": {
    backTop: "",
    nextRight: "",
    nextBottom: "/indexPage",
    indexBottom: "",
    indexTop: "",
    homeTop: "",
    homeBottom: "",
    //other content
    title: "Home",
    description: "Home Page",
  },
  "/indexPage": {
    backTop: "",
    nextRight: "/biography",
    nextBottom: "/biography",
    indexBottom: "",
    indexTop: "",
    homeTop: "/",
    homeBottom: "",
    //other content
    title: "Index Page",
    description: "Go to any chapter",
  },
  "/biography": {
    backTop: "/indexPage",
    nextRight: "/band",
    nextBottom: "/band",
    indexBottom: "/indexPage",
    indexTop: "/indexPage",
    homeTop: "",
    homeBottom: "",
    //other content
    title: "Biography",
    description: "The Rusty Eye Story.",
  },
  "/band": {
    backTop: "/biography",
    nextRight: "/discography",
    nextBottom: "/discography",
    indexBottom: "/indexPage",
    indexTop: "/indexPage",
    homeTop: "",
    homeBottom: "",
    //other content
    title: "The Trio",
    description: "Mr Rust, Miss Randall & Baron Murtland.",
  },
  "/discography": {
    backTop: "/band",
    nextRight: "/store",
    nextBottom: "/store",
    indexBottom: "/indexPage",
    indexTop: "/indexPage",
    homeTop: "",
    homeBottom: "",
    //other content
    title: "Music",
    description: "Discography.",
  },
  "/store": {
    backTop: "/discography",
    nextRight: "/blog",
    nextBottom: "/blog",
    indexBottom: "/indexPage",
    indexTop: "/indexPage",
    homeTop: "",
    homeBottom: "",
    //other content
    title: "Store",
    description: "Official merch directly from the band.",
  },
  "/blog": {
    backTop: "/store",
    nextRight: "/shows",
    nextBottom: "/shows",
    indexBottom: "/indexPage",
    indexTop: "/indexPage",
    homeTop: "",
    homeBottom: "",
    //other content
    title: "News",
    description: "Blog, News, Reviews & Press.",
  },
  "/shows": {
    backTop: "/blog",
    nextRight: "/",
    nextBottom: "/",
    indexBottom: "/indexPage",
    indexTop: "/indexPage",
    homeTop: "",
    homeBottom: "",
    //other content
    title: "Shows",
    description: "Concert Dates.",
  },
};

export { App as default, AppNavOptions };
