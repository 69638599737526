import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import styled from "styled-components";
import axios from "axios";
import { getAPIUrl } from "Utils/helpers";
import map from "lodash/map";
import get from "lodash/get";

const HeaderNav = styled.div`
  background-color: #36302f;
  font-family: "Teko", sans-serif;
  font-size: 1.5em;
  .navbar-nav {
    .nav-link {
      color: #fff;
      &:hover {
        color: #ddd5bf;
      }
      .active {
        color: #ddd5bf;
      }
    }
    .show {
      & > .nav-link {
        color: #ddd5bf;
      }
    }
  }

  .navbar-toggler {
    background-color: #989284;
    color: #36302f;
    margin: 10px auto;
  }

  .searchInput {
    position: relative;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding-right: 20px;
    width: 100%;

    input {
      font-size: 16px;
      border: none;
      &:focus {
        box-shadow: none;
      }
    }

    .searchIcon {
      position: absolute;
      top: 8px;
      right: 15px;
      font-size: 0.8em;
      color: #36302f;
    }
  }
`;

const BlogHeader = () => {
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    axios.get(`${getAPIUrl(true)}/menu/react-blog-menu`).then((resp) => {
      setMenu(resp.data);
    });
    // return () => {};
  }, []);

  if (menu.length === 0) {
    return null; //don't render if no menu is available
  }

  return (
    <HeaderNav>
      <Navbar collapseOnSelect expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {map(menu, (item) => {
              if (get(item, "submenu", []).length > 0) {
                console.log(item.submenu);
                return (
                  <NavDropdown key={item.ID} title={item.title}>
                    {map(item.submenu, (subItem) => (
                      <NavDropdown.Item key={subItem.ID} href={subItem.url}>
                        {subItem.title}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                );
              } else {
                return (
                  <Nav.Link key={item.ID} href={item.url}>
                    {item.title}
                  </Nav.Link>
                );
              }
            })}
          </Nav>
          <Form inline action="/" method="get">
            <div className="searchInput">
              <FormControl
                type="text"
                placeholder="Search"
                className="mr-sm-2"
                name="s"
              />
              <FontAwesomeIcon className="searchIcon" icon="search" />
            </div>
          </Form>
        </Navbar.Collapse>
      </Navbar>
    </HeaderNav>
  );
};

BlogHeader.propTypes = {};

export default BlogHeader;
