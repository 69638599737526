import React from "react";
import Pagination from "react-bootstrap/Pagination";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import RustyEyeButtonLink from "PageWrap/RustyEyeButtonLink";

const PaginationWrap = styled.div`
  display: inline-block;
  font-family: "Teko", sans-serif;
  .pagination {
    margin-bottom: 0px;
    .page-item {
      &.ellipsis {
        a {
          cursor: default;
          outline: none;
          &:focus {
            text-decoration: none;
            box-shadow: none;
          }
        }
      }
      &.active {
        .page-link {
          color: #bfb4a8;
          text-decoration: underline;
        }
      }
      .page-link {
        font-size: 1.5em;
        color: #36302f;
        padding: 0 10px;
        line-height: initial;
        background-color: transparent;
        border: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;

const BlogPagination = ({ currentPage, totalPages, handlePageChange }) => {
  const pageItems = [];

  const PageItem = ({ page, currentPage }) => {
    return (
      <Pagination.Item
        key={page}
        active={page === currentPage}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    );
  };

  // 1
  if (currentPage === 1) {
    pageItems.push(<PageItem key={2} page={2} currentPage={currentPage} />);
    pageItems.push(<Pagination.Ellipsis className="ellipsis" key={"el-2"} />);
  }
  // 2 - 4
  if (currentPage > 1 && currentPage <= 3) {
    for (let i = 2; i <= 4; i++) {
      //pages
      pageItems.push(<PageItem key={i} page={i} currentPage={currentPage} />);
    }
    //ellipsis
    pageItems.push(<Pagination.Ellipsis className="ellipsis" key={"el-3"} />);
  }
  //Middle pages between 4 - (currentPage + 3) => 1...3,4,5...16
  if (currentPage > 3 && currentPage + 3 <= totalPages) {
    //ellipsis
    pageItems.push(<Pagination.Ellipsis className="ellipsis" key={"el-4"} />);
    //pages
    for (let i = currentPage - 1; i < currentPage + 2; i++) {
      pageItems.push(<PageItem key={i} page={i} currentPage={currentPage} />);
    }
    //ellipsis
    pageItems.push(<Pagination.Ellipsis className="ellipsis" key={"el-5"} />);
  }

  // lastPage - 2 range 1 ... 13,14,15,16
  if (currentPage <= totalPages - 1 && currentPage > totalPages - 3) {
    //ellipsis
    pageItems.push(<Pagination.Ellipsis className="ellipsis" key={"el-6"} />);
    for (let i = totalPages - 3; i <= totalPages - 1; i++) {
      //pages
      pageItems.push(<PageItem key={i} page={i} currentPage={currentPage} />);
    }
  }

  //last page
  if (currentPage === totalPages) {
    pageItems.push(<Pagination.Ellipsis className="ellipsis" key={"el-7"} />);
    pageItems.push(
      <PageItem
        key={totalPages - 1}
        page={totalPages - 1}
        currentPage={currentPage}
      />
    );
  }

  return (
    <PaginationWrap>
      <Pagination>
        <PageItem page={1} currentPage={currentPage} />

        {pageItems}

        <PageItem page={totalPages} currentPage={currentPage} />
      </Pagination>
    </PaginationWrap>
  );
};

const BlogFooter = ({ currentPage, totalPages }) => {
  const history = useHistory();

  const handlePageChange = (page) => {
    window.scrollTo(0, 0);
    history.push(`/blog/${page}`);
  };

  return (
    <div className="row">
      <div className="col-6 col-md-4 align-self-center">
        {currentPage > 1 && (
          <RustyEyeButtonLink
            large
            dark
            text="Newer Post"
            onClick={() => handlePageChange(currentPage - 1)}
          />
        )}
        {currentPage === 1 && (
          <BlogPagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        )}
      </div>
      <div className="col-md-4 align-self-center d-none d-md-block">
        {currentPage > 1 && currentPage !== totalPages && (
          <BlogPagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        )}
      </div>
      <div className="col-6 col-md-4 align-self-center text-right">
        {currentPage !== totalPages && (
          <RustyEyeButtonLink
            large
            dark
            text="Older Post"
            onClick={() => handlePageChange(currentPage + 1)}
          />
        )}
        {currentPage === totalPages && (
          <BlogPagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

BlogFooter.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default BlogFooter;
