import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styled from "styled-components";
import SocialMedia from "./SocialMedia";
import RustyEyeBlack from "Assets/img/rustyEye_black.svg";
import RustyEyeWhite from "Assets/img/rustyEye_white.svg";
import NavigationArrow from "./NavigationArrow";
import IndexLink from "./IndexLink";
import PageTitle, { PageTitleProps } from "./PageTitle";
import { AppNavOptions } from "App";
import { useHistory, Link } from "react-router-dom";
import breakpoint from "Utils/breakpoint";
import { useScroll } from "Utils/hooks";
import isEmpty from "lodash/isEmpty";

/** margin-rights help align the hand outsize of div size */
const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 25px;
  .contentRow {
    /* background-color: blue; */
    margin-top: 50px;
    margin-bottom: 50px;
    &.withPageTitle {
      margin-top: 50px;
    }
    @media (max-width: ${breakpoint.md}) {
      min-height: 350px;
    }
  }
  .pageTitle {
    /* background-color: red; */
    margin-top: 50px;
    margin-left: 0;
    /* margin-left: -25px; */
    margin-right: -25px;
    h1 {
      margin-left: -25px;
    }
    &.noLogo {
      margin-left: 0px !important;
    }
  }

  @media (min-width: ${breakpoint.md}) {
    padding: 60px;
    .contentRow {
      margin-top: 90px;
      margin-bottom: 90px;
      &.withPageTitle {
        margin-top: 45px;
      }
    }
    .pageTitle {
      margin-top: 90px;
      /* margin-left: -60px; */
      margin-right: -60px;
      h1 {
        margin-left: -60px;
      }
    }
  }

  @media (min-width: ${breakpoint.lg}) {
    padding: 80px;
    .contentRow {
      margin-top: 180px;
      margin-bottom: 180px;
      &.withPageTitle {
        margin-top: 90px;
      }
    }

    .pageTitle {
      margin-top: 180px;
      margin-left: 8.33333%;
      margin-right: -80px;
      h1 {
        margin-left: -80px;
      }
    }
  }

  .footerLink {
    margin-bottom: 0px;
    @media (min-width: ${breakpoint.md}) {
      margin-bottom: 70px;
    }
  }
  .nextRightFixed {
    position: fixed;
    top: 40%;
  }
`;

const RustyEyeLogo = styled.img`
  width: 100%;
  max-width: 300px;
  display: inline-block;
  user-select: none;
  cursor: pointer;
`;

/**
 * Switch between arrow types by using bootstrap display classes
 */
const IndexLinkWrap = ({ homePath, indexPath, className }) => {
  return (
    <div className={classnames(className)}>
      {indexPath && (
        <>
          <IndexLink
            type="index"
            title="Index"
            path={indexPath}
            className="d-none d-md-inline-block"
          />
          <NavigationArrow
            type="indexLeft"
            title="Index"
            path={indexPath}
            className="d-inline-block d-md-none"
          />
        </>
      )}
      {homePath && (
        <>
          <IndexLink
            type="home"
            title="Home"
            path={homePath}
            className="d-none d-md-inline-block"
          />
          <NavigationArrow
            type="homeLeft"
            title="Home"
            path={homePath}
            className="d-inline-block d-md-none"
          />
        </>
      )}
    </div>
  );
};

/**
 * Contains all of the pages and contains route navigation
 */
const Page = ({
  children,
  className,
  logoColor = "black",
  pageTitleOptions,
}) => {
  const history = useHistory();
  const [pathname, setPathname] = useState("/");

  useEffect(() => {
    setPathname(history.location.pathname);
  }, [history]);

  //this allows urls like /blog/2
  const splitPathname = pathname.split("/");
  const rootPathname = `/${splitPathname[1]}`;
  const navOptions = AppNavOptions[rootPathname] || {};

  //next right
  const [nextRightReachBottom, setNextRightReachBottom] = useState(false);
  const [nextRightFixed, setNextRightFixed] = useState(false);

  const { scrollY, reachBottom } = useScroll();

  useEffect(() => {
    setNextRightReachBottom(reachBottom);
    return () => {};
  }, [reachBottom]);

  useEffect(() => {
    setNextRightFixed(scrollY > 300);
    return () => {};
  }, [scrollY]);

  return (
    <PageContainer className={classnames("container-fluid", className)}>
      <div className="row">
        <div className="col-4">
          {pathname !== "/" && (
            <Link to="/">
              <RustyEyeLogo
                src={logoColor === "white" ? RustyEyeWhite : RustyEyeBlack}
                alt="Rusty Eye"
              />
            </Link>
          )}
        </div>
        <div className="col-4 text-center">
          <NavigationArrow
            type="backUp"
            title="Back"
            path={navOptions.backTop}
          />
        </div>
        <div className="col-4 text-right">
          <IndexLinkWrap
            indexPath={navOptions.indexTop}
            homePath={navOptions.homeTop}
          />
        </div>
      </div>
      {/* page content */}
      {!isEmpty(pageTitleOptions) && (
        <PageTitle
          className={classnames("pageTitle", {
            //remove extra left space if no logo
            noLogo:
              pageTitleOptions && pageTitleOptions.logoColor === undefined,
          })}
          {...pageTitleOptions}
        />
      )}

      <div
        className={classnames("row contentRow", {
          withPageTitle: !isEmpty(pageTitleOptions),
        })}
      >
        <div className="col-1 d-none d-lg-block"></div>
        <div className="col-12 col-lg-10">{children}</div>
        <div className="col-1 d-none d-lg-block">
          {!nextRightReachBottom && (
            <NavigationArrow
              type="nextRight"
              title="Next"
              className={classnames({ nextRightFixed: nextRightFixed })}
              path={navOptions.nextRight}
            />
          )}
        </div>
      </div>

      <div className="row align-items-end">
        <div className="col-4 position-relative">
          <IndexLinkWrap
            homePath={navOptions.homeBottom}
            indexPath={navOptions.indexBottom}
            className="footerLink"
          />

          <SocialMedia className="d-none d-md-block" />
        </div>
        <div className="col-4 text-center">
          <NavigationArrow
            type="nextDown"
            title="Next"
            path={navOptions.nextBottom}
            pulse
          />
        </div>
        <div className="col-4"></div>
      </div>
    </PageContainer>
  );
};

Page.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  logoColor: PropTypes.oneOf(["black", "white"]),
  pageTitleOptions: PropTypes.shape(PageTitleProps),
};

export default Page;
