import React, { useState } from "react";
import styled from "styled-components";
import forOwn from "lodash/forOwn";
import get from "lodash/get";
import { AppNavOptions } from "App";
import { useHistory } from "react-router-dom";
import breakpoint from "Utils/breakpoint";
import classnames from "classnames";

const ListGroup = styled.ul`
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
  margin-top: 40px;
  li.list-group-item {
    padding: 10px 15px;
    background-color: transparent;
    border-bottom: 3px solid #fff;
    line-height: 1;
    font-size: 10px;
    transition: all 0.3s ease-in;
    .row {
      padding-top: 20px;
      padding-bottom: 20px;
      cursor: pointer;
      transition: all 0.3s ease-in;
      &.active {
        color: #184267;
        background-color: #f2d64b;
      }
    }
    .title {
      font-size: 2.5em;
      font-weight: bold;
      margin-left: -5px; /*prevents changing default row behavior */
    }
    .description {
      font-size: 1.8em;
      font-weight: lighter;
      margin-right: -5px; /*prevents changing default row behavior */
    }
  }

  .descriptionWrap {
    padding-left: 0px;
  }

  @media (min-width: ${breakpoint.md}) {
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;
    li.list-group-item {
      font-size: 14px;
      border-bottom: 5px solid #fff;
    }
  }

  @media (min-width: ${breakpoint.lg}) {
    li.list-group-item {
      font-size: 16px;
    }
  }
`;

const Menu = () => {
  const [activeRow, setActiveRow] = useState(-1);
  const history = useHistory();
  const MenuList = [];

  forOwn(AppNavOptions, (value, key) => {
    if (key === "/" || key === "/indexPage") {
      return;
    }
    //needs vertical alignment
    MenuList.push(
      <li
        key={key}
        className="list-group-item fontTeko"
        onMouseEnter={() => setActiveRow(key)}
        onTouchStart={() => setActiveRow(key)}
        onTouchEnd={() => setActiveRow("")}
        onMouseLeave={() => setActiveRow("")}
      >
        <div
          className={classnames("row", { active: activeRow === key })}
          onClick={() => {
            history.push(key);
          }}
        >
          <div className="col-5 col-sm-6 align-self-center">
            <span className="title">{get(value, "title")}</span>
          </div>
          <div className="col-7 col-sm-6 text-right align-self-center descriptionWrap">
            <span className="description">{get(value, "description")}</span>
          </div>
        </div>
      </li>
    );
    // idx++;
  });

  return (
    <ListGroup className="list-group list-group-flush">{MenuList}</ListGroup>
  );
};

export default Menu;
